import { capitalize } from 'lodash';
import React from 'react';
import { StyleSheet, View, Text } from 'react-native';

import { Kid } from '@boTypes/kid';
import { formatTZ } from '@utils/date';

import {
  GAP,
  TIME_LABEL_WIDTH,
  DAY_LABEL_HEIGHT,
  TILE_HEIGHT,
} from './constants';
import Day from './Day';
import { colorTokens } from '../../../../themes';
import { CalendarDayMap, WeekTuple } from '../types';

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    gap: GAP,
    height: '100%',
    // @ts-ignore
    overflowX: 'auto',
  },
  timeLabels: {
    marginTop: DAY_LABEL_HEIGHT + GAP - TILE_HEIGHT / 2,
    width: TIME_LABEL_WIDTH,
    gap: GAP,
  },
  label: {
    lineHeight: TILE_HEIGHT,
    textAlign: 'center',
    color: colorTokens.content.primary,
    fontSize: 12,
  },
  emptyLabel: { height: TILE_HEIGHT },
});

interface CalendarProps {
  week: WeekTuple;
  data: CalendarDayMap;
  selectedChild: Kid;
}

const Calendar = ({ week, data }: CalendarProps) => (
  <View style={styles.container}>
    <View style={styles.timeLabels}>
      {Array.from({ length: 25 }).map((_, i) =>
        i % 6 === 0 ? (
          <Text key={i} style={styles.label}>
            {i}h
          </Text>
        ) : (
          <View key={i} style={styles.emptyLabel} />
        ),
      )}
    </View>
    {Array.from({ length: 7 }).map((_, i) => (
      <Day
        key={i}
        label={capitalize(formatTZ(week[i], 'ddd\nDD'))}
        data={data[formatTZ(week[i], 'YYYY-MM-DD')]}
      />
    ))}
  </View>
);

export default React.memo<CalendarProps>(Calendar);
