import { useTranslate } from 'ra-core';
import { useState } from 'react';
import { Handle, NodeProps, Position, useReactFlow } from 'reactflow';

import { StyledIconButton } from '@components/discussions/styledIconButton';
import { ClampTypography } from '@components/generic/ClampTypography';
import AccountTreeIcon from '@mui/icons-material/AccountTree';
import Add from '@mui/icons-material/Add';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import InsertPhotoIcon from '@mui/icons-material/InsertPhoto';
import QuestionMarkIcon from '@mui/icons-material/QuestionMark';
import { Box, IconButton, Tooltip } from '@mui/material';
import {
  BooleanOperator,
  Condition,
  FormStep,
  QuestionType,
  StepLink,
} from '@teammay/form-core';

import { StepNodeType } from '../../types';
import { copyQuestion } from '../../utils/copy';
import {
  ConditionForm,
  DisplayConditionEdit,
} from '../conditions/ConditionEdit';
import { EditWrapper } from '../EditWrapper';
import { MenuWrapper } from '../MenuWrapper';
import { CopyQuestionDialog } from '../questions/CopyQuestionDialog';
import { StepEdit } from '../steps/StepEdit';
export function StepNode({
  id,
  data: { step, onChange, link },
}: NodeProps<StepNodeType['data']>) {
  const translate = useTranslate();

  const { setNodes } = useReactFlow();
  const handleChanged = (result: Partial<FormStep>) => {
    onChange({ updatedStep: result as FormStep });
    setNodes((nodes) =>
      nodes.map((node) => {
        if (node.id === id) {
          node.data = { ...node.data, question: result };
        }
        return node;
      }),
    );
  };
  const [condition, setCondition] = useState<ConditionForm | null>(null);
  const [questionCopySelection, setQuestionCopySelection] =
    useState<boolean>(false);
  return (
    <>
      <Handle type="target" position={Position.Top} isConnectable={false} />
      <MenuWrapper
        content={step}
        onChange={handleChanged}
        FormComponent={StepEdit}
        onDelete={
          step.links?.length
            ? undefined
            : () => onChange({ deleteStep: step.id })
        }
        targetObject={{ type: 'step', id }}
      >
        <Box
          sx={{
            p: 1,
            height: '100%',
            width: '100%',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Box sx={{ flex: 1, width: '100%' }}>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'flex-start',
                alignItems: 'center',
              }}
            >
              {Boolean(link) && (
                <IconButton
                  size="small"
                  onClick={() => setCondition(link.condition)}
                >
                  <AccountTreeIcon sx={{ fontSize: '0.825rem' }} />
                </IconButton>
              )}
              <ClampTypography variant="caption">{step.title}</ClampTypography>
            </Box>
          </Box>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-around',
              gap: 1,
            }}
          >
            <Tooltip title={translate('forms.step.createQuestion')}>
              <StyledIconButton
                onClick={() =>
                  onChange({
                    addQuestion: {
                      id: self.crypto.randomUUID(),
                      title: 'New Question',
                      description: '',
                      type: QuestionType.RADIO,
                      hideCondition: null,
                      options: [],
                      stepId: step.id,
                      order: 0,
                    },
                  })
                }
              >
                <QuestionMarkIcon />
              </StyledIconButton>
            </Tooltip>
            <Tooltip title={translate('forms.step.copyQuestion')}>
              <StyledIconButton onClick={() => setQuestionCopySelection(true)}>
                <ContentCopyIcon />
              </StyledIconButton>
            </Tooltip>
            <Tooltip title={translate('forms.step.createTemplate')}>
              <StyledIconButton
                onClick={() =>
                  onChange({
                    addTemplate: {
                      id: self.crypto.randomUUID(),
                      title: 'New Template',
                      description: '',
                      stepId: step.id,
                      order: 0,
                      image: '',
                    },
                  })
                }
              >
                <InsertPhotoIcon />
              </StyledIconButton>
            </Tooltip>
          </Box>
        </Box>
      </MenuWrapper>
      <EditWrapper
        content={condition as ConditionForm}
        onSave={(content) => {
          Object.assign(link, { condition: { id: condition.id, ...content } });
          setCondition(null);
        }}
        open={!!condition}
        onCancel={() => setCondition(null)}
        FormComponent={DisplayConditionEdit}
        defaultValues={{ booleanOperator: BooleanOperator.AND }}
        targetObject={{ type: 'step', id }}
      />
      <Handle
        type="source"
        position={Position.Bottom}
        className={'newStepHandle'}
        onClick={() => {
          const to = {
            id: self.crypto.randomUUID(),
            title: 'New Step',
            links: [],
          };
          const newCondition: Condition = {
            id: self.crypto.randomUUID(),
            booleanOperator: BooleanOperator.AND,
            children: [],
            questionRules: [],
            dataRules: [],
          };
          const newLink: StepLink = {
            id: self.crypto.randomUUID(),
            stepId: step.id,
            nextStepId: to.id,
            order: step.links.length,
            conditionId: newCondition.id,
            condition: newCondition,
          };
          onChange({
            addStep: {
              from: step as FormStep,
              to,
              link: newLink,
            },
          });
        }}
      >
        <Add
          color="primary"
          sx={{
            backgroundColor: 'white',
            borderRadius: '50%',
            width: '1.5rem',
            height: '1.5rem',
            borderWidth: '1px',
            borderStyle: 'solid',
            borderColor: 'primary.main10',
            cursor: 'pointer',
          }}
        />
      </Handle>
      <CopyQuestionDialog
        open={questionCopySelection}
        onClose={() => setQuestionCopySelection(false)}
        onClick={(question) => {
          onChange({
            addQuestion: copyQuestion(question, step.id),
          });
        }}
      />
    </>
  );
}
