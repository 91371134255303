import { DiscussionQuickFilter } from '@boTypes/discussion';
import { DiscussionEventType } from '@boTypes/discussionEvent';
import { ATTRIBUTION_OPTIONS_ENUM } from '@boTypes/subject';

import { trackEvent } from '.';

export const logLogin = async (email: string) => trackEvent('Login', { email });

export const logLogout = async () => trackEvent('Logout');

export const logFilter = async (filter: DiscussionQuickFilter) =>
  trackEvent('FilterToggled', { filter });

export const logSubjectTabSelected = async (tab: ATTRIBUTION_OPTIONS_ENUM) =>
  trackEvent('SubjectTabSelected', { tab });

export const logDiscussionView = async (
  discussionId: number,
  subjectId?: number,
) => trackEvent('DiscussionView', { discussionId, subjectId });

export const logStartedSession = async (params: {
  from: 'ChatInput' | 'AppBar';
}) => trackEvent('StartedSession', params);

export const logStoppedSession = async () => trackEvent('StoppedSession');

export const logSentChatMessage = async ({
  subjectId,
  discussionId,
  contentType,
  contentId,
}: {
  subjectId: number;
  discussionId: number;
  contentType: DiscussionEventType;
  contentId?: string;
}) =>
  trackEvent('SentChatMessage', {
    subjectId,
    discussionId,
    contentType,
    contentId,
  });

export const logClickOnGreetings = async (mode: 'manual' | 'auto') =>
  trackEvent('ClickOnGreetings', { mode });

export const logRequestDoctorIntervention = async (subjectId: number) =>
  trackEvent('RequestDoctorIntervention', { subjectId });

export const logRequestNurseIntervention = async (subjectId: number) =>
  trackEvent('RequestNurseIntervention', { subjectId });

export const logSetAsPriority = async (subjectId: number) =>
  trackEvent('SetAsPriority', { subjectId });

export const logClickOnAppert = async (subjectId: number) =>
  trackEvent('ClickOnAppert', { subjectId });

export const logCloseSubject = async (subjectId: number) =>
  trackEvent('CloseSubject', { subjectId });

export const logAddedFollowUp = async (familyId: string) =>
  trackEvent('AddedFollowUp', { familyId });

export const logCreateHandover = async (
  subjectId: number,
  withRevive: boolean,
) => trackEvent('CreateHandover', { subjectId, withRevive });

export const logEditHandover = async (subjectId: number) =>
  trackEvent('EditHandover', { subjectId });

export const logHandoverCreateButton = async (subjectId: number) =>
  trackEvent('HandoverCreateButton', { subjectId });

export const logHandoverEditButton = async (subjectId: number) =>
  trackEvent('HandoverEditButton', { subjectId });

export const logAddedReviveToHandover = async (subjectId: number) =>
  trackEvent('AddedReviveToHandover', { subjectId });

export const logUpdatedReviveToHandover = async (subjectId: number) =>
  trackEvent('UpdatedReviveToHandover', { subjectId });

export const logClosedRevive = async (reviveId: string) =>
  trackEvent('ClosedRevive', { reviveId });

export const logPostedAndClosedRevive = async (reviveId: string) =>
  trackEvent('PostedAndClosedRevive', { reviveId });

export const logAvailabilitySlotChoiceSelected = async () =>
  trackEvent('AvailabilitySlotChoiceSelected');

export const logAvailabilityPreferencesSaved = async () =>
  trackEvent('AvailabilityPreferencesSaved');

export const logAvailabilitySave = async () => trackEvent('AvailabilitySave');

export const logAvailabilityAutoSave = async () =>
  trackEvent('AvailabilityAutoSave');

export const logMenuSelect = async (name: string) =>
  trackEvent('MenuSelect', { name });

export const logLibraryFromChat = async (subjectId: number) =>
  trackEvent('LibraryMenu', { subjectId });

export const logContentSuggestion = async (subjectId: number) =>
  trackEvent('ContentSuggestion', { subjectId });

export const logContentSuggestionResults = async (
  subjectId: number,
  total: number,
  macro: number,
  posts: number,
  guides: number,
  masterclasses: number,
) =>
  trackEvent('ContentSuggestionResults', {
    subjectId,
    total,
    macro,
    posts,
    guides,
    masterclasses,
  });

export const logContentSuggestionView = async (
  subjectId: number,
  type: string,
  contentId: number,
) => trackEvent('ContentSuggestionView', { subjectId, contentId, type });

export const logContentView = async (type: string, contentId: number) =>
  trackEvent('ContentView', { contentId, type });

export const logContentSuggestionSend = async (
  subjectId: number,
  type: string,
  contentId: number,
) => trackEvent('ContentSuggestionSend', { subjectId, contentId, type });

export const logContentSuggestionMacroSuggestions = async (
  subjectId: number,
  macroSuggestionId: number,
  macroId: number,
) =>
  trackEvent('ContentSuggestionMacroSuggestions', {
    subjectId,
    macroSuggestionId,
    macroId,
  });

export const logContentSendFromLibrary = async (
  subjectId: number,
  type: string,
  contentId: number,
) => trackEvent('ContentSendFromLibrary', { subjectId, contentId, type });

export const logMacroSuggestionFromLibrary = async (
  subjectId: number,
  macroId: number,
  macroSuggestionId: number,
) =>
  trackEvent('MacroSuggestionFromLibrary', {
    subjectId,
    macroSuggestionId,
    macroId,
  });

export const logRequestMoreAttributions = async () =>
  trackEvent('RequestMoreAttributions');

export const logTookSubjectFromUnattributed = async (subjectId: number) =>
  trackEvent('TookSubjectFromUnattributed', { subjectId });

export const logLibrarySearch = async (
  search: string,
  ageLevel?: number,
  category?: number,
  pregnancy?: string | null,
) => trackEvent('LibrarySearch', { search, ageLevel, category, pregnancy });

export const logLibraryModeSelect = async (mode: string) =>
  trackEvent('LibraryModeSelect', { mode });

export const logLibraryFilter = async ({
  ageLevel,
  category,
}: {
  ageLevel?: number;
  category?: number;
}) => trackEvent('LibraryFilter', { ageLevel, category });

export const logLibraryContentTypeSelected = async (type: string) =>
  trackEvent('LibraryContentTypeSelected', { type });

export const logContentSuggestionFeedback = async (
  subjectId: number,
  isPositive: boolean,
) => trackEvent('ContentSuggestionFeedback', { subjectId, isPositive });

export const logSimilarQuestionOpen = async (subjectId: number) =>
  trackEvent('SimilarQuestionOpen', { subjectId });

export const logSimilarQuestionSubjectSelected = async (
  subjectId: number,
  similarSubjectId: number,
) =>
  trackEvent('SimilarQuestionSubjectSelected', { subjectId, similarSubjectId });

export const logAcceptHandoverGeneration = async (
  subjectId: number,
  field: string = 'all',
  context: string = 'nurse',
) => trackEvent('AcceptAllHandoverGeneration', { subjectId, field, context });

export const logViewTherapyOnboardingForm = async (therapySlug: string) =>
  trackEvent('ViewTherapyOnboardingForm', { slug: therapySlug });

export const logViewTherapyDetails = async (therapySlug: string) =>
  trackEvent('ViewTherapyDetails', { slug: therapySlug });

export const logViewDiscussionFromConsultation = async (
  consultationId: number,
) => trackEvent('ViewDiscussionFromConsultation', { consultationId });

export const logViewTherapyFromDiscussion = async (therapySlug: string) =>
  trackEvent('ViewTherapyFromDiscussion', { slug: therapySlug });

export const logSubmitFormAnswersProAnalysis = async ({
  formId,
  formAnswersId,
}: {
  formId?: string;
  formAnswersId?: string;
}) => trackEvent('SubmitFormAnswersProAnalysis', { formId, formAnswersId });
