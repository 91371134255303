import { ActivityIndicator, StyleSheet, View } from 'react-native';

import {
  useDailyTipItem,
  useFolderItem,
  useGuideItem,
  useMasterclassItem,
  usePostItem,
  useWeeklyTipItem,
} from '@hooks/cms';
import { useChatFormList } from '@hooks/form-builder';
import { ContentType } from '@teammay/mayssenger';

import {
  FORM_CARD_HEIGHT,
  FormCard,
} from '../../../../entities/form/components/FormCard';
import {
  HEIGHT as DT_HEIGHT,
  DailyTipItem,
} from '../../../DailyTip/DailyTipItem';
import {
  HEIGHT as FOLDER_HEIGHT,
  FolderItem,
} from '../../../Folder/FolderItem';
import { HEIGHT as GUIDE_HEIGHT, GuideItem } from '../../../Guide/GuideItem';
import {
  HEIGHT as MC_HEIGHT,
  MasterclassItem,
} from '../../../Masterclass/MasterclassItem';
import { HEIGHT as POST_HEIGHT, PostItem } from '../../../Post/PostItem';
import {
  HEIGHT as WT_HEIGHT,
  WeeklyTipItem,
} from '../../../WeeklyTip/WeeklyTipItem';
const containerStyles = StyleSheet.create({
  container: {
    alignItems: 'center',
    backgroundColor: 'primary.subtle',
    height: 96,
    width: 50,
    justifyContent: 'center',
  },
});

const Loader = () => (
  <View style={containerStyles.container}>
    <ActivityIndicator color={'primary.main'} size="large" />
  </View>
);

export const DailyTipMessage = ({
  lookup,
  onLongPress,
}: {
  lookup: number | string;
  onLongPress?: () => void;
}) => {
  const { data, isLoading } = useDailyTipItem(lookup);

  if (isLoading || !data) {
    return (
      <View style={{ height: DT_HEIGHT }}>
        <Loader />
      </View>
    );
  }
  return <DailyTipItem item={data} onLongPress={onLongPress} />;
};

export const WeeklyTipMessage = ({
  lookup,
  onLongPress,
}: {
  lookup: number | string;
  onLongPress?: () => void;
}) => {
  const { data, isLoading } = useWeeklyTipItem(lookup);

  if (isLoading || !data) {
    return (
      <View style={{ height: WT_HEIGHT }}>
        <Loader />
      </View>
    );
  }
  return <WeeklyTipItem item={data} onLongPress={onLongPress} />;
};

export const PostMessage = ({
  lookup,
  onLongPress,
}: {
  lookup: string;
  onLongPress?: () => void;
}) => {
  const { data, isLoading } = usePostItem(lookup);

  if (isLoading || !data) {
    return (
      <View style={{ height: POST_HEIGHT }}>
        <Loader />
      </View>
    );
  }
  return <PostItem item={data} onLongPress={onLongPress} />;
};

export const MasterclassMessage = ({
  lookup,
  onLongPress,
}: {
  lookup: number | string;
  onLongPress?: () => void;
}) => {
  const { data, isLoading } = useMasterclassItem(lookup);

  if (isLoading || !data) {
    return (
      <View style={{ height: MC_HEIGHT }}>
        <Loader />
      </View>
    );
  }
  return <MasterclassItem item={data} onLongPress={onLongPress} />;
};

export const GuideMessage = ({
  lookup,
  onLongPress,
}: {
  lookup: string;
  onLongPress?: () => void;
}) => {
  const { data, isLoading } = useGuideItem(lookup);

  if (isLoading || !data) {
    return (
      <View style={{ height: GUIDE_HEIGHT }}>
        <Loader />
      </View>
    );
  }
  return <GuideItem item={data} onLongPress={onLongPress} />;
};

export const FolderMessage = ({
  lookup,
  onLongPress,
}: {
  lookup: string;
  onLongPress?: () => void;
}) => {
  const { data, isLoading } = useFolderItem(lookup);

  if (isLoading || !data) {
    return (
      <View style={{ height: FOLDER_HEIGHT }}>
        <Loader />
      </View>
    );
  }
  return <FolderItem item={data} onLongPress={onLongPress} />;
};

export const ContentMessage = ({
  contentType,
  contentId,
  onLongPress,
}: {
  contentType: ContentType;
  contentId: string;
  onLongPress?: () => void;
}) => {
  switch (contentType) {
    case ContentType.dailyTip:
      return <DailyTipMessage lookup={contentId} onLongPress={onLongPress} />;
    case ContentType.weeklyTip:
      return <WeeklyTipMessage lookup={contentId} onLongPress={onLongPress} />;
    case ContentType.article:
      return <PostMessage lookup={contentId} onLongPress={onLongPress} />;
    case ContentType.masterclass:
      return (
        <MasterclassMessage lookup={contentId} onLongPress={onLongPress} />
      );
    case ContentType.guide:
      return <GuideMessage lookup={contentId} onLongPress={onLongPress} />;
    case ContentType.folder:
      return <FolderMessage lookup={contentId} onLongPress={onLongPress} />;
    default:
      return null;
  }
};

export const FormMessage = ({ formId }: { formId: string }) => {
  const { data } = useChatFormList({ enabled: false });
  const form = data?.find((f) => f.id === formId);
  return form ? (
    <FormCard {...form} style={{ height: FORM_CARD_HEIGHT }} />
  ) : (
    <View style={{ height: FORM_CARD_HEIGHT }}>
      <Loader />
    </View>
  );
};
