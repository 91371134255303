import { useGetIdentity, useTranslate } from 'react-admin';
import { Link } from 'react-router-dom';

import { PlanningJob } from '@boTypes/planning';
import { StaffUser } from '@boTypes/staffUser';
import { titleToColor, titleToPlanningJob } from '@boTypes/user';
import { Avatar } from '@components/fields/AvatarField';
import { Loader } from '@components/Loader';
import { Bookmark, Key } from '@mui/icons-material';
import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  Chip,
  Typography,
} from '@mui/material';

const UserJobBadge = ({ user }: { user: StaffUser }) => {
  const translate = useTranslate();

  const color = titleToColor[user?.jobTitle];
  const label = {
    [PlanningJob.DOCTOR]: translate('common.doctorShort'),
    [PlanningJob.NURSE]: translate('common.nurseShort'),
    [PlanningJob.MIDWIFE]: translate('common.midwifeShort'),
    all: translate('common.admin'),
  }[titleToPlanningJob[user?.jobTitle]];

  return <Chip label={label} sx={{ backgroundColor: color }} />;
};

const Identity = ({ user }: { user: StaffUser }) => {
  const translate = useTranslate();

  return (
    <Box>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          width: '100%',
        }}
      >
        <Avatar
          user={user}
          size="custom"
          sx={{ marginRight: '1rem', width: '5rem', height: '5rem' }}
        />
        <Box sx={{ width: '100%' }}>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              justifyContent: 'space-between',
            }}
          >
            <Typography
              variant="h6"
              color="default"
              sx={{ fontWeight: 'bold' }}
            >
              {user?.fullName} {languageToFlag(user?.language)}
            </Typography>
            <UserJobBadge user={user} />
          </Box>
          <Typography variant="body1" color="textSecondary">
            {user?.email}
          </Typography>
          <Typography variant="body1" color="textSecondary">
            {user?.phone}
          </Typography>
        </Box>
      </Box>
      <Button
        component={Link}
        startIcon={<Bookmark />}
        size="small"
        to="/favorites"
        variant="text"
        color="inherit"
        sx={{ color: 'primary.main75', mt: 2 }}
      >
        {translate('favorites.dashboard')}
      </Button>
    </Box>
  );
};

const languageToFlag = (language: string) => {
  switch (language) {
    case 'fr':
      return '🇫🇷';
    case 'en':
      return '🇬🇧';
    case 'es':
      return '🇪🇸';
    default:
      return '';
  }
};

export const UserCard = () => {
  const translate = useTranslate();
  const { identity: user, isLoading } = useGetIdentity();

  return (
    <Card elevation={2} sx={{ height: '100%' }}>
      <CardContent sx={{ height: 'calc(100% - 3rem)' }}>
        <Typography
          variant="h6"
          color="primary"
          sx={{ fontWeight: 'bold', marginBottom: '0.5rem' }}
        >
          {translate('profile.title')}
        </Typography>
        {/* @ts-ignore */}
        {isLoading ? <Loader /> : <Identity user={user} />}
      </CardContent>
      <CardActions
        sx={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'center',
          alignContent: 'center',
        }}
      >
        <Button variant="text" to={'/profile'} component={Link}>
          {translate('profile.update')}
        </Button>
        <Button
          component={Link}
          startIcon={<Key />}
          size="small"
          to="/profile-password"
          variant="text"
          color="inherit"
          sx={{ color: 'primary.main75' }}
        >
          {translate('profile.changePassword')}
        </Button>
      </CardActions>
    </Card>
  );
};
