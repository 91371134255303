import { StyleSheet } from 'react-native';

import { guideDesignTokens, guideTheme } from './resources/guideTheme';

const { fonts } = guideDesignTokens;

export const styles = StyleSheet.create({
  container: {
    flex: 1,
    justifyContent: 'flex-end',
    flexDirection: 'column',
  },
  footerSpace: { paddingBottom: 40 },

  footer: {
    paddingHorizontal: 24,
    paddingVertical: 8,
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },

  pageNumber: fonts.body.md,
});

export const markdownStyles = (theme) =>
  StyleSheet.create({
    body: { flex: 1, padding: 24, gap: 16 },

    paragraph: { marginTop: 0, marginBottom: 0 },

    heading1: { ...fonts.h1, color: guideTheme[theme]?.color },
    heading2: { ...fonts.h2, color: guideTheme[theme]?.color },
    heading3: { ...fonts.h3, color: guideTheme[theme]?.color },
    heading4: { ...fonts.h4 },
    text: { ...fonts.body.sm, color: guideTheme[theme]?.color },

    squareImage: { aspectRatio: 1 },
    imageContent: { width: '100%', height: '100%' },

    mediumImage: { height: 80 },
    smallImage: { width: 32, height: 32 },

    list: {
      alignItems: 'flex-start',
      flexDirection: 'row',
      marginVertical: 4,
      gap: 8,
    },
    imageList: { alignItems: 'center' },
    bulletContainer: {
      height: fonts.body.sm.lineHeight,
      justifyContent: 'center',
    },
    bullet: {
      height: 4,
      aspectRatio: 1,
      borderRadius: 2,
      backgroundColor: guideTheme[theme]?.color,
    },
    bulletContent: { flex: 1 },

    blockquotes: {
      padding: 16,
      flexDirection: 'row',
      alignItems: 'center',
      backgroundColor: guideTheme[theme]?.accent,
      borderRadius: 8,
    },

    table: {
      borderRadius: 0,
      borderWidth: 0,
      borderLeftWidth: 1,
      borderTopWidth: 1,
      borderColor: guideTheme[theme]?.color,
    },
    th: {
      borderRightWidth: 1,
      borderColor: guideTheme[theme]?.color,
    },
    td: {
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'flex-start',
      borderRightWidth: 1,
      gap: 4,
      borderColor: guideTheme[theme]?.color,
    },
    tr: {
      borderColor: guideTheme[theme]?.color,
    },
  });
