import { useTranslate } from 'react-admin';

import { Roles } from '@boTypes/user';
import { CalendarMonth, Receipt } from '@mui/icons-material';
import {
  Card,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Typography,
} from '@mui/material';

import { useSelector } from '../../store';

export const PlanningCard = () => {
  const translate = useTranslate();
  const roles = useSelector((state) => state.user.roles);
  const isAdmin = roles.includes(Roles.ADMIN);

  if (!isAdmin && !roles.includes(Roles.STAFF_MANAGER)) {
    return null;
  }

  return (
    <Card sx={{ padding: 2 }}>
      <Typography variant="subtitle1" color="primary" sx={{ paddingBottom: 1 }}>
        Planning
      </Typography>
      <List dense>
        <ListItem disablePadding>
          <ListItemButton disableGutters component="a" href="/#/planning">
            <ListItemIcon sx={{ minWidth: '2rem' }}>
              <CalendarMonth fontSize="small" />
            </ListItemIcon>
            <ListItemText primary={translate('admin.planning.admin')} />
          </ListItemButton>
        </ListItem>
        {isAdmin && (
          <ListItem disablePadding>
            <ListItemButton
              disableGutters
              component="a"
              href="/#/admin/invoices"
            >
              <ListItemIcon sx={{ minWidth: '2rem' }}>
                <Receipt fontSize="small" />
              </ListItemIcon>
              <ListItemText primary={translate('invoice.title')} />
            </ListItemButton>
          </ListItem>
        )}
      </List>
    </Card>
  );
};
