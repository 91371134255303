import { StyleProp, StyleSheet, View, ViewStyle, Text } from 'react-native';

import { colorTokens } from '../../../../themes';
import { TILE_HEIGHT, TILE_WIDTH } from '../Calendar/constants';

const styles = StyleSheet.create({
  container: {
    gap: 16,
    rowGap: 8,
    flexDirection: 'row',
    justifyContent: 'center',
    flexWrap: 'wrap',
  },
  element: { gap: 8, flexDirection: 'row', alignItems: 'center' },
  tile: { height: TILE_HEIGHT, width: TILE_WIDTH, borderRadius: 4 },
  label: {
    color: colorTokens.content.primary,
  },
});

export type LegendElement = { label: string; color: string };
interface LegendProps {
  style?: StyleProp<ViewStyle>;
  elements: LegendElement[];
}
const Legend = ({ style, elements }: LegendProps) => (
  <View style={[styles.container, style]}>
    {elements.map(({ label, color }, id) => (
      <View style={styles.element} key={id.toString()}>
        <View style={[styles.tile, { backgroundColor: color }]} />
        <Text style={styles.label}>{label}</Text>
      </View>
    ))}
  </View>
);

export default Legend;
