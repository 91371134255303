import { useEffect, useMemo, useState } from 'react';
import { Button, useTranslate } from 'react-admin';
import { utils as xlsUtils, writeFileXLSX } from 'xlsx';

import { Planning } from '@boTypes/planning';
import { StaffUserInvoice } from '@boTypes/staffUserInvoice';
import { Roles } from '@boTypes/user';
import { Loader } from '@components/Loader';
import {
  Chat,
  DeleteOutline,
  Download,
  FindInPage,
  UploadFile,
} from '@mui/icons-material';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { Box, Card, CardContent, Popover, Typography } from '@mui/material';

import {
  useDeleteInvoice,
  useInvoices,
  useLatestPlannings,
  usePlanningSummary,
} from './hooks';
import { InvoiceDialog } from './invoice.viewer';
import { InvoiceUploadDialog } from './invoices.form';
import { getXLSXFromUserInvoiceData } from '../../entities/plannings/utils';
import { useSelector } from '../../store';
import { getFullName } from '../../utils';
import { getReadableTimeRange } from '../../utils/date';

const Accordion = ({
  defaultExpanded,
  title,
  children,
}: {
  defaultExpanded?: boolean;
  title: React.ReactNode;
  children: React.ReactNode;
}) => {
  const [expanded, setExpanded] = useState(defaultExpanded ?? false);

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        textAlign: 'left',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          cursor: 'pointer',
        }}
        onClick={() => setExpanded(!expanded)}
      >
        <KeyboardArrowUpIcon
          sx={{
            transform: expanded ? 'none' : 'rotate(180deg)',
            transition: 'transform 0.3s ease-in-out',
          }}
        />
        {title}
      </Box>
      <Box
        sx={{
          maxHeight: expanded ? 'none' : 0,
          opacity: expanded ? 1 : 0,
          overflow: 'hidden',
          transition: 'max-height 0.3s ease-in-out, opacity 0.3s ease-in-out',
        }}
      >
        {children}
      </Box>
    </Box>
  );
};

export const InvoicesCard = () => {
  const translate = useTranslate();
  const roles = useSelector((state) => state.user.roles);
  const userId = useSelector((state) => state.user.userId);
  const [selectedPlanningId, setSelectedPlanningId] = useState<
    Planning['id'] | null
  >(null);

  const { plannings, isLoading: isLoadingPlannings } = useLatestPlannings();

  const { invoices, isLoading } = useInvoices({
    enabled:
      roles.includes(Roles.HEALTH_PRO) &&
      !isLoadingPlannings &&
      plannings.length > 0,
  });

  const [openUploadDialog, setOpenUploadDialog] = useState<
    Planning['id'] | false
  >(false);
  const [openViewerDialog, setOpenViewerDialog] = useState(false);
  const [selectedInvoice, setSelectedInvoice] = useState<string | undefined>(
    undefined,
  );

  const [invoiceForComments, setInvoiceForComments] = useState<
    string | undefined
  >();

  const handleInvoiceClick = (invoiceUrl: string) => {
    setSelectedInvoice(invoiceUrl);
    setOpenViewerDialog(true);
  };

  const handleSummaryClick = (planningId: Planning['id']) => {
    setSelectedPlanningId(planningId);
  };

  const { summary, isLoadingSummary } = usePlanningSummary(
    selectedPlanningId,
    userId,
    { enabled: roles.includes(Roles.HEALTH_PRO) },
  );

  useEffect(() => {
    if (isLoadingSummary || !selectedPlanningId || !summary) {
      return;
    }

    const workbook = xlsUtils.book_new();
    const workSheet = getXLSXFromUserInvoiceData(
      summary.invoiceData,
      summary.planning,
    );
    xlsUtils.book_append_sheet(workbook, workSheet, 'Facture');
    writeFileXLSX(
      workbook,
      `${getFullName(summary.staffUser)
        .trim()
        .replace(' ', '_')}_${selectedPlanningId}.xlsx`,
    );
    setSelectedPlanningId(null);
  }, [summary, isLoadingSummary, selectedPlanningId]);

  const { deleteInvoice, isDeleting } = useDeleteInvoice();

  // Group invoices by planning
  const groupedInvoices = useMemo(() => {
    if (
      !plannings?.length ||
      isLoadingPlannings ||
      isLoading ||
      invoices === undefined
    ) {
      return [];
    }
    return plannings.map((p) => ({
      ...p,
      invoice: invoices.find((i) => i.planningId === p.id),
    }));
  }, [plannings, invoices, isLoadingPlannings, isLoading]);

  const [anchorEl, setAnchorEl] = useState<HTMLDivElement | null>(null);

  const handleClick = (
    event: React.MouseEvent<HTMLDivElement>,
    invoiceId: StaffUserInvoice['id'],
  ) => {
    setInvoiceForComments(invoiceId);
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setInvoiceForComments(undefined);
  };

  if (!roles.includes(Roles.HEALTH_PRO)) {
    return null;
  }

  const open = Boolean(anchorEl);

  return (
    <Card elevation={2} sx={{ height: '100%' }}>
      <CardContent>
        <Typography
          variant="h6"
          color="primary"
          sx={{ fontWeight: 'bold', marginBottom: '0.5rem' }}
        >
          {translate('invoice.title')}
        </Typography>
        {isLoadingPlannings || isLoading ? (
          <Loader />
        ) : groupedInvoices.length > 0 ? (
          groupedInvoices.map((planning, ndx) => (
            <Accordion
              title={
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    alignContent: 'center',
                    alignItems: 'center',
                    width: '100%',
                  }}
                >
                  <Box
                    sx={{
                      display: 'flex',
                      flexDirection: 'row',
                      alignItems: 'center',
                      color: 'primary.main50',
                    }}
                  >
                    <Typography
                      key={planning.id}
                      variant="subtitle2"
                      sx={{ marginRight: '1rem' }}
                    >
                      {planning.title}
                    </Typography>
                    {planning.withActivity && (
                      <Button
                        variant="text"
                        color="inherit"
                        startIcon={<Download />}
                        label={translate('invoice.summary')}
                        sx={{ colors: 'primary.main50' }}
                        disabled={isLoadingSummary}
                        onClick={(ev) => {
                          handleSummaryClick(planning.id);
                          ev.stopPropagation();
                        }}
                      />
                    )}
                  </Box>

                  <Typography color="gray" variant="caption">
                    {getReadableTimeRange(planning.begin, planning.end)}
                  </Typography>
                </Box>
              }
              key={planning.id}
              defaultExpanded={ndx === 0}
            >
              {planning.invoice && (
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    marginLeft: '1.5rem',
                  }}
                >
                  <Box
                    sx={{
                      display: 'flex',
                      flexDirection: 'row',
                      alignItems: 'center',
                      gap: '0.5rem',
                    }}
                  >
                    <Typography
                      sx={{ color: 'primary.main50' }}
                      variant="subtitle2"
                    >
                      {planning.invoice.validated
                        ? '✅ ' + translate('invoice.validatedInvoice')
                        : '🕙 ' + translate('invoice.pendingInvoice')}
                    </Typography>
                    {planning.invoice.comments ? (
                      <Box
                        onClick={(ev) => handleClick(ev, planning.invoice.id)}
                        sx={{ color: 'primary.main50' }}
                      >
                        <Chat sx={{ fontSize: '1rem' }} />
                      </Box>
                    ) : null}
                    <Popover
                      id={`popover-${planning.invoice.id}`}
                      open={open && invoiceForComments === planning.invoice.id}
                      anchorEl={anchorEl}
                      onClose={handleClose}
                    >
                      <Typography sx={{ p: 1 }}>
                        {planning.invoice.comments}
                      </Typography>
                    </Popover>
                  </Box>

                  <Box
                    sx={{
                      display: 'flex',
                      flexDirection: 'row',
                      gap: '0.5rem',
                    }}
                  >
                    {!planning.invoice.validated && (
                      <Button
                        variant="text"
                        startIcon={<DeleteOutline />}
                        label={translate('invoice.deleteShort')}
                        sx={{ marginRight: '0.5rem' }}
                        onClick={() => deleteInvoice(planning.invoice.id)}
                        disabled={isDeleting}
                      />
                    )}
                    <Button
                      variant="text"
                      startIcon={<FindInPage />}
                      label={translate('invoice.see')}
                      onClick={() =>
                        handleInvoiceClick(planning.invoice.invoiceURL)
                      }
                      sx={{ color: 'primary.main50' }}
                    />
                  </Box>
                </Box>
              )}
              {/* Check if invoice needs to be uploaded */}
              {planning.withActivity ? (
                !planning.invoice?.validated && (
                  <Box>
                    <Button
                      variant="text"
                      color={planning.invoice ? 'inherit' : 'primary'}
                      startIcon={<UploadFile />}
                      label={translate('invoice.upload')}
                      sx={{
                        marginBottom: '0.5rem',
                        color: planning.invoice ? 'primary.main75' : undefined,
                      }}
                      onClick={() => {
                        setOpenUploadDialog(planning.id);
                      }}
                    />
                  </Box>
                )
              ) : (
                <Typography variant="body2" color="textSecondary">
                  {translate('invoice.noActivity')}
                </Typography>
              )}
              <InvoiceUploadDialog
                open={!!openUploadDialog && openUploadDialog === planning.id}
                onClose={() => setOpenUploadDialog(false)}
                planningId={planning.id}
              />
              <InvoiceDialog
                open={openViewerDialog}
                onClose={() => setOpenViewerDialog(false)}
                invoiceUrl={selectedInvoice ?? ''}
              />
            </Accordion>
          ))
        ) : (
          <Typography variant="body1" color="textSecondary">
            {translate('invoice.empty')}
          </Typography>
        )}
      </CardContent>
    </Card>
  );
};
