import { useTranslate } from 'react-admin';

import { DiscussionEventType } from '@boTypes/discussionEvent';
import { Guide } from '@boTypes/guide';
import { Macro } from '@boTypes/macroType';
import { Masterclass } from '@boTypes/masterclass';
import { Post } from '@boTypes/post';
import { GuidesList } from '@components/Guide/GuidesList';
import { Loader } from '@components/Loader';
import { MacroList } from '@components/Macro/MacroList';
import { MasterclassList } from '@components/Masterclass/MasterclassList';
import { PostsList } from '@components/Post/PostsList';
import { DifferenceOutlined, Info } from '@mui/icons-material';
import { Box, Button, Paper, Typography } from '@mui/material';

import { ContentSuggestionFeedback } from './Feedback';
import { logSimilarQuestionOpen } from '../../../../analytics/events';
import { SimilarQuestionDialog } from '../../../../entities/similarQuestion/dialog';

export const ContentSuggestion = ({
  onClose,
  guides,
  posts,
  masterclasses,
  macro,
  isLoading,
  handleContentSend,
  handleCopyContent,
  setSimilarQuestionDialog,
  openSimilarQuestionDialog,
  subjectId,
}: {
  onClose?: () => void;
  isLoading: boolean;
  guides: Guide[];
  posts: Post[];
  masterclasses: Masterclass[];
  macro: Macro[];
  subjectId: number;
  setSimilarQuestionDialog?: (value: boolean) => void;
  openSimilarQuestionDialog?: boolean;
  handleContentSend: (
    content: { type: DiscussionEventType; content: string },
    contentType: string,
    contentId: number,
    fromSuggestion?: boolean,
  ) => void;
  handleCopyContent: (
    content: string,
    macroId: number,
    suggestionId: number,
    fromSuggestion?: boolean,
  ) => void;
}) => {
  const translate = useTranslate();
  const hasSuggestions =
    !isLoading &&
    (macro.length > 0 ||
      guides.length > 0 ||
      posts.length > 0 ||
      masterclasses.length > 0);
  return (
    <Paper elevation={0} sx={{ m: 0, p: 0 }}>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          mx: 2,
          mt: 2,
        }}
      >
        <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
          {translate('contentSuggestion.title')}
        </Typography>
      </Box>
      <Box sx={{ display: 'flex', alignItems: 'center', mb: 1, mx: 2 }}>
        <Info sx={{ mr: 1, color: 'primary.main50', fontSize: '1rem' }} />
        <Typography
          variant="body2"
          sx={{
            color: 'text.secondary',
            verticalAlign: 'center',
            fontSize: '0.75rem',
          }}
        >
          {translate('contentSuggestion.subtitle')}
        </Typography>
      </Box>
      {setSimilarQuestionDialog && (
        <Button
          size="small"
          onClick={() => {
            logSimilarQuestionOpen(subjectId);
            setSimilarQuestionDialog(true);
          }}
          color="primary"
          sx={{ mx: 2 }}
          startIcon={<DifferenceOutlined />}
        >
          {translate('similarQuestion.button')}
        </Button>
      )}
      {openSimilarQuestionDialog && (
        <SimilarQuestionDialog
          opened={openSimilarQuestionDialog}
          close={() => {
            onClose?.();
            setSimilarQuestionDialog(false);
          }}
          subjectId={subjectId}
        />
      )}
      {isLoading && <Loader />}
      {!hasSuggestions && (
        <Typography sx={{ m: 2 }}>
          {translate('contentSuggestion.empty')}
        </Typography>
      )}
      {hasSuggestions && (
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'flex-start',
            alignItems: 'center',
            marginLeft: 2,
            marginRight: 2,
            gap: 2,
          }}
        >
          <Typography variant="body2">
            {translate('contentSuggestion.assessment')}
          </Typography>
          <ContentSuggestionFeedback subjectId={subjectId} />
        </Box>
      )}

      <Box sx={{ marginLeft: 2 }}>
        <MacroList
          list={macro}
          onCopy={handleCopyContent}
          fromSuggestion={true}
          subjectId={subjectId}
        />
        <GuidesList
          list={guides}
          onSend={handleContentSend}
          fromSuggestion={true}
          subjectId={subjectId}
        />
        <PostsList
          list={posts}
          onSend={handleContentSend}
          fromSuggestion={true}
          subjectId={subjectId}
        />
        <MasterclassList
          list={masterclasses}
          onSend={handleContentSend}
          fromSuggestion={true}
          subjectId={subjectId}
        />
      </Box>

      <Box
        sx={{
          mx: 2,
          mt: 2,
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        {hasSuggestions && (
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'flex-start',
              alignItems: 'center',
              gap: 2,
            }}
          >
            <Typography variant="body2">
              {translate('contentSuggestion.assessment')}
            </Typography>
            <ContentSuggestionFeedback subjectId={subjectId} />
          </Box>
        )}
      </Box>
    </Paper>
  );
};
