import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Badge, IconButton } from '@mui/material';

export const ScrollToBottom = ({
  unreadMessagesCount,
  onPress,
}: {
  unreadMessagesCount?: number;
  onPress: () => void;
}) => {
  return (
    <Badge
      sx={{
        position: 'absolute',
        bottom: 20,
        right: 10,
        backgroundColor: '#ffffff99',
        borderRadius: 20,
        borderWidth: '1px',
        borderStyle: 'solid',
        borderColor: 'primary.main10',
      }}
      badgeContent={unreadMessagesCount}
      color="primary"
    >
      <IconButton
        color="primary"
        //@ts-ignore
        onClick={() => onPress()}
      >
        <ExpandMoreIcon />
      </IconButton>
    </Badge>
  );
};
