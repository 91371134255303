import { useRef } from 'react';
import { useTranslate } from 'react-admin';
import { useStore } from 'reactflow';

import { Button } from '@components/generic/Button';
import { DialogTitle } from '@components/generic/Dialog';
import {
  Dialog,
  Select,
  MenuItem,
  DialogContent,
  DialogActions,
} from '@mui/material';
import { Question } from '@teammay/form-core';

export const CopyQuestionDialog = ({
  open,
  onClose,
  onClick,
}: {
  open: boolean;
  onClose: () => void;
  onClick: (question: Question) => void;
}) => {
  const translate = useTranslate();
  const questions = useStore((store) =>
    store
      .getNodes()
      .filter((node) => node.type === 'question')
      .map((node) => node.data.question as Question),
  );
  const selectedQuestion = useRef<Question | null>(null);
  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle onClose={onClose}>
        {translate('forms.questions.copy.title')}
      </DialogTitle>
      <DialogContent>
        <Select
          variant="outlined"
          label={translate('forms.questions.copy.question')}
          defaultValue={questions[0]?.id ?? ''}
          onChange={(event) => {
            selectedQuestion.current = questions.find(
              (question) => question.id === event.target.value,
            );
          }}
        >
          {questions.map((question) => (
            <MenuItem key={question.id} value={question.id}>
              {question.title}
            </MenuItem>
          ))}
        </Select>
      </DialogContent>
      <DialogActions
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          mx: 2,
        }}
      >
        <Button onClick={() => onClose()} color="inherit">
          {translate('common.cancel')}
        </Button>
        <Button
          variant="contained"
          onClick={() => {
            onClose();
            selectedQuestion.current && onClick(selectedQuestion.current);
          }}
          disabled={!selectedQuestion.current}
          autoFocus
        >
          {translate('common.copy')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
