import { Box, Typography } from '@mui/material';

import { colorTokens } from '../../../themes';
import { isSameDay } from '../../../utils/date';
import { formats } from '../formats';

const weekHeaderStyle = {
  wrapper: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    textAlign: 'center',
  },
  day: {
    fontSize: '0.75rem',
    color: colorTokens.surface.grey,
  },
  today: {
    color: colorTokens.content.secondary,
    fontWeight: 'bold',
  },
  number: {
    fontSize: '1.5rem',
    lineHeight: '1.5rem',
    color: colorTokens.content.secondary,
  },
};

export const weekHeader = ({ date, localizer }) => {
  const [day, numberInMonth] = localizer
    .format(date, formats.dayFormat)
    .split(' ');

  const isToday = isSameDay(new Date(), date);

  return (
    // @ts-ignore
    <Box style={weekHeaderStyle.wrapper}>
      <Typography
        style={{
          ...weekHeaderStyle.day,
          ...(isToday ? weekHeaderStyle.today : {}),
        }}
      >
        {day.slice(0, -1)}
      </Typography>
      <Typography
        style={{
          ...weekHeaderStyle.number,
          ...(isToday ? weekHeaderStyle.today : {}),
        }}
      >
        {numberInMonth}
      </Typography>
    </Box>
  );
};
