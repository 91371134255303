import { StyleProp, StyleSheet, ViewStyle } from 'react-native';

import { ArrowLeft } from '@mui/icons-material';

import { PressableNative } from '../../../../entities/form/components/mobile-components/buttons/PressableNative';
import { colorTokens } from '../../../../themes';

const styles = StyleSheet.create({
  container: {
    width: 32,
    aspectRatio: 1,
    borderRadius: 16,
    backgroundColor: colorTokens.content.subtle,
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center',
    display: 'flex',
  },
  rotate: { transform: [{ rotate: '180deg' }] },
  opacity: { opacity: 0.5 },
});

interface ActionsProps {
  style?: StyleProp<ViewStyle>;
  onPress: () => void;
  disabled?: boolean;
}

const ActionUI = ({ style, onPress, disabled }: ActionsProps) => (
  <PressableNative
    style={[styles.container, style, disabled && styles.opacity]}
    onPress={onPress}
    disabled={disabled}
  >
    <ArrowLeft />
  </PressableNative>
);

export const NextButton = ({ style, ...props }: ActionsProps) => (
  <ActionUI style={[style, styles.rotate]} {...props} />
);

export const PreviousButton = (props: ActionsProps) => <ActionUI {...props} />;
