import React from 'react';
import { useTranslate } from 'react-admin';
import ReactMarkdown from 'react-markdown';

import { DiscussionEventType } from '@boTypes/discussionEvent';
import {
  CMSCard,
  CMSCardActionArea,
  CMSCardContent,
  CMSCardSentOverlay,
  CMSCardTitle,
} from '@components/cmsCard';
import { SuggestionContentType } from '@components/discussions/chat/contentSuggestion/types';
import { useHandleLongPress } from '@hooks/useHandleLongPress';
import DownloadDoneIcon from '@mui/icons-material/DownloadDone';
import {
  Box,
  CardActions,
  Dialog,
  DialogContent,
  Divider,
  List,
  ListItem,
  styled,
  useTheme,
} from '@mui/material';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';

import {
  logContentSuggestionView,
  logContentView,
} from '../../analytics/events';
import { Masterclass } from '../../types';
import { SendIcon } from '../discussions/SendIcon';
import { DialogTitle } from '../generic/Dialog';

export const WIDTH = 280;
const TITLE_LINE_NB = 3;
const TITLE_HEIGHT = TITLE_LINE_NB * 40;
export const HEIGHT = TITLE_HEIGHT + 10;

const StyledImage = styled('img')({
  width: '20%',
  height: '100%',
  objectFit: 'cover',
});

export const MasterclassItemDialog = ({
  item,
  opened,
  close,
}: {
  item: Masterclass;
  opened: boolean;
  close: () => void;
}) => {
  const { formats, url } = item?.masterclass_expert.photo || {
    url: item?.expertImageThumbnailUrl ?? '',
  };
  const translate = useTranslate();

  return (
    <Dialog
      open={opened}
      onClose={close}
      aria-labelledby={item.title}
      aria-describedby={item.title}
      maxWidth="md"
    >
      <DialogTitle
        onClose={close}
        sx={{
          fontFamily: 'Lora, serif',
          fontWeight: 600,
          color: 'primary.main',
        }}
      >
        {item.title}
      </DialogTitle>
      <DialogContent>
        <Box sx={{ display: 'flex', flexDirection: 'row' }}>
          <StyledImage src={formats?.medium?.path ?? url} alt={item.title} />
          <Box sx={{ display: 'flex', flexDirection: 'column' }}>
            <Typography
              variant="subtitle2"
              component="h2"
              color="primary"
              sx={{ marginLeft: '1rem' }}
            >
              {translate('library.summary')}
            </Typography>
            <ReactMarkdown>{item.summary}</ReactMarkdown>
          </Box>
        </Box>
        <Divider sx={{ py: 1 }} />
        {item.trackTitles && (
          <>
            <Typography
              variant="subtitle2"
              component="h2"
              color="primary"
              sx={{ py: '1rem' }}
            >
              {translate('library.tracks')}
            </Typography>
            <List disablePadding dense>
              {item.trackTitles.map((trackTitle, index) => (
                <ListItem disableGutters key={trackTitle}>
                  {`${index + 1} - ${trackTitle}`}
                </ListItem>
              ))}
            </List>
          </>
        )}
      </DialogContent>
    </Dialog>
  );
};

export const MasterclassItem = ({
  item,
  onSend,
  onLongPress,
  fromSuggestion,
  subjectId,
}: {
  item: Masterclass;
  onSend?: (
    {
      type,
      content,
    }: {
      type: DiscussionEventType;
      content: string;
    },
    contentType: string,
    contentId: number,
    fromSuggestions?: boolean,
    subjectId?: number,
  ) => void;
  onLongPress?: () => void;
  fromSuggestion?: boolean;
  subjectId?: number;
}) => {
  const { title, masterclass_expert: expert } = item;
  const { formats, url } = expert.photo || {
    url: item?.expertImageThumbnailUrl ?? '',
  };
  const [open, setOpen] = React.useState(false);
  const onClick = () => {
    if (fromSuggestion) {
      logContentSuggestionView(
        subjectId,
        SuggestionContentType.MASTERCLASSES,
        item.id,
      );
    } else {
      logContentView('masterclass', item.id);
    }
    setOpen(true);
  };
  const close = () => setOpen(false);
  const masterclassInfo = {
    type: DiscussionEventType.MASTERCLASS,
    content: String(item.entryId || item.id),
  };
  const [handlePress, startClick] = useHandleLongPress(onClick, onLongPress);
  const theme = useTheme();

  return (
    <CMSCard
      width={WIDTH}
      height={HEIGHT}
      elevation={0}
      style={{
        // @ts-ignore
        backgroundColor: theme.palette.secondary.main75,
      }}
    >
      {open ? (
        <MasterclassItemDialog item={item} opened={open} close={close} />
      ) : null}
      <CMSCardActionArea
        height={HEIGHT}
        onClick={handlePress}
        onMouseDown={startClick}
        sx={{ overflow: 'hidden' }}
      >
        {item.sent && <CMSCardSentOverlay />}
        <Box
          sx={{
            position: 'absolute',
            borderRadius: '50%',
            width: 0.6 * WIDTH,
            height: 0.6 * WIDTH,
            right: -0.05 * WIDTH,
            bottom: -0.3 * WIDTH,
            backgroundColor: theme.palette.secondary.main,
          }}
        />
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'flex-start',
            flex: 1,
            width: '100%',
            zIndex: 3,
          }}
        >
          <CMSCardContent sx={{ justifyContent: 'flex-start', flex: 3 }}>
            <CMSCardTitle
              variant="subtitle2"
              component="h2"
              clamp={2}
              sx={{
                fontFamily: 'Lora, serif',
                fontWeight: 'bold',
                textAlign: 'left',
                marginBottom: 1,
                fontSize: '1rem',
              }}
            >
              {title}
            </CMSCardTitle>
            <Typography
              variant="body2"
              component="h3"
              sx={{
                textAlign: 'left',
                paddingLeft: 1,
                fontFamily: 'Lora, serif',
                overflow: 'hidden',
                display: '-webkit-box',
                WebkitLineClamp: '2',
                WebkitBoxOrient: 'vertical',
                borderLeftWidth: 2,
                borderLeftStyle: 'solid',
                // @ts-ignore
                borderLeftColor: theme.palette.secondary.main50,
                flex: 1,
                fontWeight: 'bold',
                fontSize: '0.75rem',
              }}
            >
              {expert.name}
            </Typography>
            <Typography
              variant="body2"
              component="h4"
              sx={{
                fontSize: '0.625rem',
                paddingLeft: 1,
                color: 'text.secondary',
                textAlign: 'left',
              }}
            >
              {expert.title}
            </Typography>
          </CMSCardContent>
          <CardMedia
            sx={{
              zIndex: 2,
              maxWidth: 0.4 * WIDTH,
            }}
            component="img"
            alt={title}
            height={HEIGHT}
            image={formats?.thumbnail?.path ?? url}
            title={title}
          />
        </Box>
        {item.sent && (
          <DownloadDoneIcon
            sx={(_theme) => ({
              position: 'absolute',
              left: _theme.spacing(1),
              bottom: _theme.spacing(0),
            })}
          />
        )}
      </CMSCardActionArea>
      {onSend ? (
        <CardActions sx={{ padding: 0 }}>
          <SendIcon
            onClick={() =>
              onSend(
                masterclassInfo,
                DiscussionEventType.MASTERCLASS,
                item.id,
                fromSuggestion,
                subjectId,
              )
            }
            sx={{ zIndex: 5 }}
          />
        </CardActions>
      ) : null}
    </CMSCard>
  );
};
