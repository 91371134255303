import { AxiosError } from 'axios';
import { useCallback, useState } from 'react';
import { useTranslate } from 'react-admin';

import { useMutation } from '@hooks/queryWrappers';
import {
  ChildCare,
  LocalHospital,
  FolderCopy,
  Opacity,
} from '@mui/icons-material';
import WarningIcon from '@mui/icons-material/Warning';
import { Box, Button, CircularProgress } from '@mui/material';

import {
  logClickOnAppert,
  logCloseSubject,
  logRequestDoctorIntervention,
  logRequestNurseIntervention,
  logSetAsPriority,
} from '../../analytics/events';
import { DiscussionContext } from '../../common';
import { useDiscussionDetail } from '../../hooks/discussion';
import { useDiscussionContext } from '../../hooks/useDiscussionContext';
import { Subject } from '../../types';
import {
  SetDoctorInterventionInput,
  SetNurseInterventionInput,
} from '../../types/subject';
import { conditionalColor } from '../../utils/conditionalColor';
import { AppertDialog } from '../dialogs/appert';

export const SubjectActionButton = () => {
  const translate = useTranslate();
  const discussion = useDiscussionDetail();
  const discussionContext = useDiscussionContext();
  const switchPriority = useMutation<
    Subject,
    AxiosError,
    Pick<Subject, 'isPriority' | 'id'>
  >(
    ['subjectPriority'],
    (currentSubject) => ({
      method: 'put',
      url: `/api/subjects/${
        currentSubject?.isPriority ? 'unsetAsPriority' : 'setAsPriority'
      }/${currentSubject.id}`,
    }),
    {
      onSuccess(data, { isPriority }) {
        if (!isPriority) {
          logSetAsPriority(discussion.lastSubjectId);
        }
        discussion.updateSubject({ subject: data });
      },
    },
  );
  const [openAppert, setOpenAppert] = useState(false);

  const switchMedicalIntervention = useMutation<
    Subject,
    AxiosError,
    SetDoctorInterventionInput
  >(
    ['switchDoctor', discussion?.lastSubject?.id],
    (updates: SetDoctorInterventionInput) => ({
      method: 'put',
      url: `/api/subjects/medicalIntervention/${discussion.lastSubject.id}/${discussion.id}`,
      data: updates,
    }),
    {
      onSuccess(data) {
        discussion.updateSubject({ subject: data });
      },
    },
  );

  const switchNurseIntervention = useMutation<
    Subject,
    AxiosError,
    SetNurseInterventionInput
  >(
    ['switchNurse', discussion?.lastSubject?.id],
    (updates: SetNurseInterventionInput) => ({
      method: 'put',
      url: `/api/subjects/nurseIntervention/${discussion.lastSubject.id}/${discussion.id}`,
      data: updates,
    }),
    {
      onSuccess(data) {
        discussion.updateSubject({ subject: data });
      },
    },
  );

  const { mutate: closeSubject, isPending } = useMutation<
    Subject,
    AxiosError,
    void
  >(
    ['closeSubject', discussion?.lastSubject?.id],
    () => ({
      method: 'put',
      url: `/api/subjects/${discussion.lastSubject.id}/close`,
    }),
    {
      onSuccess: (res) => {
        logCloseSubject(discussion.lastSubjectId);
        return discussion.updateSubject({ subject: res });
      },
    },
  );

  const togglePriority = useCallback(() => {
    switchPriority.mutate(discussion.lastSubject);
  }, [discussion.lastSubject, switchPriority]);

  const toggleDoctor = useCallback(
    () =>
      discussion.lastSubject.withDoctorIntervention
        ? switchMedicalIntervention.mutate({
            cancel: true,
            midwife: discussionContext === DiscussionContext.MIDWIFE,
          })
        : switchMedicalIntervention.mutate(
            {
              cancel: false,
              midwife: discussionContext === DiscussionContext.MIDWIFE,
            },
            {
              onSuccess: () => {
                logRequestDoctorIntervention(discussion.lastSubjectId);
              },
            },
          ),
    [
      discussion.lastSubject?.withDoctorIntervention,
      discussion.lastSubjectId,
      discussionContext,
      switchMedicalIntervention,
    ],
  );

  const toggleNurse = useCallback(
    () =>
      discussion.lastSubject.withNurseIntervention
        ? switchNurseIntervention.mutate({
            cancel: true,
          })
        : switchNurseIntervention.mutate(
            {
              cancel: false,
            },
            {
              onSuccess: () => {
                logRequestNurseIntervention(discussion.lastSubjectId);
              },
            },
          ),
    [
      discussion.lastSubject?.withNurseIntervention,
      discussion.lastSubjectId,
      switchNurseIntervention,
    ],
  );

  if (!discussion?.lastSubject) {
    return (
      <CircularProgress
        size={16}
        sx={{
          marginLeft: '0.5rem',
          marginBottom: '0.5rem',
          marginTop: '0.5rem',
          minHeight: '16px',
        }}
      />
    );
  }

  const canClose =
    !isPending && !discussion.lastSubject.empty && !discussion.lastSubject.end;

  return (
    <>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          minHeight: '32px',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
          }}
        >
          <Button
            size="small"
            sx={{
              color: conditionalColor(discussion.lastSubject.isPriority),
            }}
            startIcon={<WarningIcon />}
            onClick={togglePriority}
          >
            <Box display={{ xs: 'none', sm: 'block' }}>
              {discussion.lastSubject.isPriority
                ? translate('subject.notPrio')
                : translate('subject.prio')}
            </Box>
          </Button>

          {discussionContext === DiscussionContext.MIDWIFE && (
            <Button
              startIcon={<ChildCare />}
              size="small"
              sx={{
                color: conditionalColor(
                  discussion.lastSubject.withNurseIntervention,
                ),
              }}
              onClick={toggleNurse}
            >
              <Box display={{ xs: 'none', sm: 'block' }}>
                {discussion.lastSubject.withNurseIntervention
                  ? translate('subject.withNurseInterventionShort')
                  : translate('subject.withoutNurseInterventionShort')}
              </Box>
            </Button>
          )}
          {discussionContext === DiscussionContext.NURSE && (
            <>
              <Button
                startIcon={<LocalHospital />}
                size="small"
                sx={{
                  color: conditionalColor(
                    discussion.lastSubject.withDoctorIntervention,
                  ),
                }}
                onClick={toggleDoctor}
              >
                <Box display={{ xs: 'none', sm: 'block' }}>
                  {discussion.lastSubject.withDoctorIntervention
                    ? translate('subject.withMedicalInterventionShort')
                    : translate('subject.withoutMedicalInterventionShort')}
                </Box>
              </Button>
              <AppertDialog open={openAppert} setOpen={setOpenAppert} />
              <Button
                startIcon={<Opacity />}
                size="small"
                onClick={() => {
                  logClickOnAppert(discussion.lastSubjectId);
                  setOpenAppert(true);
                }}
                sx={{
                  color: 'background.grey',
                }}
              >
                <Box display={{ xs: 'none', sm: 'block' }}>
                  {translate('common.bottleShort')}
                </Box>
              </Button>
            </>
          )}
        </Box>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
          }}
        >
          <Button
            size="small"
            startIcon={<FolderCopy />}
            onClick={() => closeSubject()}
            disabled={!canClose}
            sx={{
              color: !canClose ? 'background.grey' : 'primary.main',
            }}
          >
            <Box display={{ xs: 'none', sm: 'block' }}>
              {translate('common.enclose')}
            </Box>
          </Button>
        </Box>
      </Box>
    </>
  );
};
