import * as React from 'react';
import { Children, cloneElement, isValidElement, useState } from 'react';
import {
  useTranslate,
  useGetIdentity,
  MenuItemLinkProps,
  Logout,
  useRedirect,
} from 'react-admin';
import { useLocation } from 'react-router';

import { Bookmark, Dashboard } from '@mui/icons-material';
import AccountCircle from '@mui/icons-material/AccountCircle';
import {
  Tooltip,
  IconButton,
  Menu,
  Avatar,
  Theme,
  ListItemIcon,
  MenuItem,
  ListItemText,
  MenuItemProps,
} from '@mui/material';
import { styled } from '@mui/material/styles';

const StyledAvatar = styled(Avatar)(({ theme }: { theme: Theme }) => ({
  width: theme.spacing(4),
  height: theme.spacing(4),
}));

const defaultProps: Props = {
  label: 'ra.auth.user_menu',
  icon: <AccountCircle />,
};

interface Props {
  children?: React.ReactNode[];
  label?: string;
  logout?: React.ReactElement;
  icon?: React.ReactNode;
}

const UserMenu = (props: Props = defaultProps) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const translate = useTranslate();
  const { identity } = useGetIdentity();

  const { children, label = 'ra.auth.user_menu', icon, logout } = props;
  if (!logout && !children) {
    return null;
  }
  const open = Boolean(anchorEl);

  const handleMenu = (event: React.MouseEvent) =>
    setAnchorEl(event.currentTarget);
  const handleClose = () => setAnchorEl(null);

  return (
    <div>
      {identity?.fullName ? (
        <IconButton
          aria-label={label && translate(label, { _: label })}
          sx={{
            lineHeight: 1.25,
            textTransform: 'none',
          }}
          color="inherit"
          onClick={handleMenu}
        >
          {identity.avatar ? (
            <StyledAvatar src={identity.avatar} alt={identity.fullName} />
          ) : (
            icon
          )}
        </IconButton>
      ) : (
        <Tooltip title={label && translate(label, { _: label })}>
          <IconButton
            aria-label={label && translate(label, { _: label })}
            aria-owns={open ? 'menu-appbar' : null}
            aria-haspopup
            color="inherit"
            onClick={handleMenu}
            size="large"
          >
            {icon}
          </IconButton>
        </Tooltip>
      )}
      <Menu
        id="menu-appbar"
        disableScrollLock
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        open={open}
        onClose={handleClose}
      >
        {Children.map(children, (menuItem) =>
          isValidElement(menuItem)
            ? // @ts-ignore
              cloneElement<MenuItemLinkProps>(menuItem, {
                handleClose,
              })
            : null,
        )}
        <Logout />
      </Menu>
    </div>
  );
};

const DashboardMenu = React.forwardRef<
  HTMLLIElement,
  MenuItemProps & { handleClose?: () => void }
>(({ handleClose, ...props }, ref) => {
  const translate = useTranslate();
  const redirect = useRedirect();
  const location = useLocation();

  return (
    <MenuItem
      ref={ref}
      onClick={() => {
        redirect('/my-dashboard');
        handleClose?.();
      }}
      {...props}
      sx={{
        backgroundColor:
          location.pathname === '/my-dashboard' ? 'primary.main10' : undefined,
      }}
    >
      <ListItemIcon>
        <Dashboard
          sx={{
            color:
              location.pathname === '/my-dashboard'
                ? 'primary.main'
                : undefined,
          }}
        />
      </ListItemIcon>
      <ListItemText
        sx={{
          color:
            location.pathname === '/my-dashboard' ? 'primary.main' : undefined,
        }}
      >
        {translate('common.dashboard')}
      </ListItemText>
    </MenuItem>
  );
});

const FavoritesMenu = React.forwardRef<
  HTMLLIElement,
  MenuItemProps & { handleClose?: () => void }
>(({ handleClose, ...props }, ref) => {
  const translate = useTranslate();
  const redirect = useRedirect();
  const location = useLocation();

  return (
    <MenuItem
      ref={ref}
      onClick={() => {
        redirect('/favorites');
        handleClose?.();
      }}
      {...props}
      sx={{
        backgroundColor:
          location.pathname === '/favorites' ? 'primary.main10' : undefined,
      }}
    >
      <ListItemIcon
        sx={{
          color:
            location.pathname === '/favorites' ? 'primary.main' : undefined,
        }}
      >
        <Bookmark />
      </ListItemIcon>
      <ListItemText
        sx={{
          color:
            location.pathname === '/favorites' ? 'primary.main' : undefined,
        }}
      >
        {translate('favorites.title')}
      </ListItemText>
    </MenuItem>
  );
});

export const MyUserMenu = (props: Props) => (
  <UserMenu {...props}>
    <DashboardMenu />
    <FavoritesMenu />
  </UserMenu>
);
