import CloseIcon from '@mui/icons-material/Close';
import SearchIcon from '@mui/icons-material/Search';
import { Box, IconButton, InputBase } from '@mui/material';

export const SearchAndAttributionFilters = ({
  text,
  onChangeText,
}: {
  text: string;
  onChangeText: (value: string) => void;
}) => {
  return (
    <Box
      sx={{
        m: 0,
        paddingLeft: 1,
        paddingRight: 1,
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        borderBottomWidth: '1px',
        borderBottomStyle: 'solid',
        borderBottomColor: 'primary.main10',
      }}
    >
      {text ? (
        <IconButton onClick={() => onChangeText('')} sx={{ p: 0 }}>
          <CloseIcon />
        </IconButton>
      ) : (
        <SearchIcon sx={{ color: 'background.grey' }} />
      )}
      <InputBase
        autoFocus
        value={text}
        sx={{ marginTop: 0, flex: 1, marginLeft: 1 }}
        onChange={(e) => onChangeText(e.target.value)}
      />
    </Box>
  );
};
