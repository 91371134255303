import './reindeer.css';

export const Reindeer = () => (
  <div className="container-xmas">
    <div className="artboard">
      <div className="deer">
        <div className="rocking">
          <div className="head">
            <div className="horns">
              <div className="horn horn-left">
                <div className="line line-one" />
                <div className="line" />
                <div className="line line-three" />
              </div>
              <div className="horn horn-right">
                <div className="line line-one" />
                <div className="line" />
                <div className="line line-three" />
              </div>
            </div>
            <div className="ears">
              <div className="ear ear-left" />
              <div className="ear ear-right" />
            </div>
            <div className="eyes">
              <div className="eye eye-left" />
              <div className="eye eye-right" />
            </div>
            <div className="nose" />
          </div>
          <div className="deerbody">
            <div className="hooves">
              <div className="hoof-one">
                <div className="line" />
                <div className="anim-part">
                  <div className="circle">
                    <div className="circle">
                      <div className="circle">
                        <div className="circle">
                          <div className="circle circle-last" />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="hoof-two">
                <div className="line-one" />
                <div className="line-two" />
              </div>
            </div>
          </div>
          <div className="tail">
            <div className="circle">
              <div className="circle">
                <div className="circle">
                  <div className="circle">
                    <div className="circle" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="legs">
          <div className="leg-left">
            <div className="anim-part">
              <div className="line" />
            </div>
          </div>
          <div className="leg-right">
            <div className="anim-part">
              <div className="circle">
                <div className="circle">
                  <div className="circle">
                    <div className="circle">
                      <div className="circle">
                        <div className="circle">
                          <div className="circle">
                            <div className="circle">
                              <div className="circle circle-last" />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="presents">
        <div className="present present-one" />
        <div className="present present-two" />
        <div className="present present-two present-two-right" />
        <div className="present present-three" />
      </div>
      <div className="snowflake" />
      <div className="snowflake" />
      <div className="snowflake" />
      <div className="snowflake" />
      <div className="snowflake" />
      <div className="snowflake" />
      <div className="snowflake" />
      <div className="snowflake" />
      <div className="snowflake" />
      <div className="snowflake" />
      <div className="snowflake" />
      <div className="snowflake" />
      <div className="snowflake" />
      <div className="snowflake" />
      <div className="snowflake" />
      <div className="snowflake" />
      <div className="snowflake" />
      <div className="snowflake" />
      <div className="snowflake" />
      <div className="snowflake" />
      <div className="snowflake" />
      <div className="snowflake" />
      <div className="snowflake" />
      <div className="snowflake" />
      <div className="snowflake" />
      <div className="snowflake" />
      <div className="snowflake" />
      <div className="snowflake" />
      <div className="snowflake" />
      <div className="snowflake" />
      <div className="snowflake" />
      <div className="snowflake" />
      <div className="snowflake" />
      <div className="snowflake" />
      <div className="snowflake" />
      <div className="snowflake" />
      <div className="snowflake" />
      <div className="snowflake" />
      <div className="snowflake" />
      <div className="snowflake" />
      <div className="snowflake" />
      <div className="snowflake" />
      <div className="snowflake" />
      <div className="snowflake" />
      <div className="snowflake" />
      <div className="snowflake" />
      <div className="snowflake" />
      <div className="snowflake" />
      <div className="snowflake" />
      <div className="snowflake" />
      <div className="snowflake" />
      <div className="snowflake" />
      <div className="snowflake" />
      <div className="snowflake" />
      <div className="snowflake" />
      <div className="snowflake" />
      <div className="snowflake" />
      <div className="snowflake" />
      <div className="snowflake" />
      <div className="snowflake" />
      <div className="snowflake" />
      <div className="snowflake" />
      <div className="snowflake" />
      <div className="snowflake" />
      <div className="snowflake" />
      <div className="snowflake" />
      <div className="snowflake" />
      <div className="snowflake" />
      <div className="snowflake" />
      <div className="snowflake" />
      <div className="snowflake" />
      <div className="snowflake" />
      <div className="snowflake" />
      <div className="snowflake" />
      <div className="snowflake" />
      <div className="snowflake" />
      <div className="snowflake" />
      <div className="snowflake" />
      <div className="snowflake" />
      <div className="snowflake" />
      <div className="snowflake" />
      <div className="snowflake" />
      <div className="snowflake" />
      <div className="snowflake" />
      <div className="snowflake" />
      <div className="snowflake" />
      <div className="snowflake" />
      <div className="snowflake" />
      <div className="snowflake" />
      <div className="snowflake" />
      <div className="snowflake" />
      <div className="snowflake" />
      <div className="snowflake" />
      <div className="snowflake" />
      <div className="snowflake" />
      <div className="snowflake" />
      <div className="snowflake" />
      <div className="snowflake" />
    </div>
  </div>
);
