export const Logo = (
  { height = 24, width = 48 } = { height: 24, width: 48 },
) => (
  <svg
    id="logo"
    width={width}
    height={height}
    viewBox={`0 0 ${width} ${height}`}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill="#073735"
      d="M4.4,8.2c1-1,2.5-2.1,4.1-2.1,1.7,0,2.8.9,3.3,2.3,1-1,2.6-2.3,4.4-2.3s3.6,1.6,3.6,3.9v6.9c0,.4.2.7.8.7h.2v.3h-5.2v-.3h.2c.6,0,.8-.3.8-.7v-6.9c0-1.4-.7-2.2-2-2.2s-1.9.4-2.6.9c.1.4.2.8.2,1.3v6.9c0,.4.2.7.8.7h.2v.3h-5.2v-.3h.2c.6,0,.8-.3.8-.7v-6.9c0-1.4-.7-2.3-2.1-2.3s-1.6.2-2.4.8v8.3c0,.4.2.7.8.7h.2v.3H.2v-.3h.2c.6,0,.8-.3.8-.7v-8.4H.1v-.2c1.1-.3,2.9-1.2,4.1-2.2h.3v2.2h0Z"
    />
    <path
      fillRule="evenodd"
      fill="#073735"
      d="M33,23.7c-.5-.3-.9-.9-.9-1.6,0-1,.8-1.9,1.9-1.9s1.9.8,1.9,1.9,0,.2,0,.3c.6-.5,1.2-1.6,2.1-3.8v-.3c.1,0-5-10.6-5-10.6-.3-.7-.6-.9-1.1-.9h-.1v-.3h5.4v.3h-.3c-.7,0-.7.4-.4.9l3.2,7.1,2.7-7.1c.2-.6.1-.9-.4-.9h-.4v-.3h2.5v.3h0c-.6,0-.8.2-1.1.9l-4.3,10.7c-2,4.8-3,5.6-4.6,5.6s-.8-.1-1-.3Z"
    />
    <path
      fillRule="evenodd"
      fill="#073735"
      d="M24.2,8.4c.4-1,1-1.5,2.2-1.5h0c1.1,0,1.5.6,1.5,1.8v2.7l-1.3.6c-3.1,1.4-4.7,2.5-4.7,4.4s.8,1.9,2.4,1.9,2.3-.5,3.7-1.7c.2,1.2.9,1.7,2.1,1.7s2-.6,2.6-1.2l-.2-.2c-.2.2-.5.3-.7.3-.5,0-.6-.3-.6-1.1v-6.5c0-2.1-1.3-3.2-3.6-3.2s-5,2.1-5.3,3.4c0,.2-.1.4-.1.6,0,1,.8,1.9,1.9,1.9s1.9-.8,1.9-1.9-.7-1.8-1.7-1.9ZM27.9,16.2c-.6.4-1.2.6-1.7.6-.8,0-1.2-.5-1.2-1.5s.5-2.1,2.5-3.4l.3-.2v4.1h0c0,.1,0,.3,0,.4Z"
    />
    <path
      fill="#AB8BE0"
      fillRule="evenodd"
      d="M46,8.3c1.1,0,1.9.8,1.9,1.9s-.9,1.9-1.9,1.9-1.9-.8-1.9-1.9.9-1.9,1.9-1.9Z"
    />
    <path
      fill="#073735"
      d="M45.9,14.2c1,0,1.9.8,1.9,1.9s-.8,1.9-1.9,1.9-1.9-.8-1.9-1.9.8-1.9,1.9-1.9Z"
    />
  </svg>
);
