import { useState } from 'react';
import { useTranslate } from 'react-admin';

import { useFormList } from '@hooks/form-builder';
import AddIcon from '@mui/icons-material/Add';
import { CircularProgress, Fab, Paper, Typography } from '@mui/material';

import { FormBuilderList } from './components/FormBuilderList';
import { FormCreateModal } from './components/FormSettingsForm';

export const FormList = () => {
  const { data: forms } = useFormList();
  const translate = useTranslate();
  const [creatingForm, setCreatingForm] = useState(false);
  const isLoading = false;
  return (
    <Paper sx={{ padding: '1rem 1rem', minHeight: '100%' }} elevation={0}>
      <Typography
        variant="h1"
        color="primary"
        sx={{ fontWeight: 'bold', marginBottom: 1 }}
      >
        {translate('forms.list.title')}
      </Typography>
      {forms?.length ? (
        <FormBuilderList forms={forms} />
      ) : (
        <Typography>{translate('forms.list.empty')}</Typography>
      )}
      <Fab
        color="primary"
        aria-label="add"
        size="small"
        sx={{
          position: 'absolute',
          bottom: 32,
          right: 32,
        }}
        disabled={isLoading}
        onClick={() => setCreatingForm(true)}
      >
        {isLoading ? <CircularProgress /> : <AddIcon />}
      </Fab>
      <FormCreateModal
        open={creatingForm}
        onClose={() => {
          setCreatingForm(false);
        }}
      />
    </Paper>
  );
};
