import { StyleSheet } from 'react-native';

import ButtonUI, { ButtonUIProps } from './ButtonUI';
import { colorTokens } from '../../../../../themes';

const styles = StyleSheet.create({
  primary: { backgroundColor: colorTokens.surface.primaryInverse },
});

type ButtonProps = Omit<ButtonUIProps, 'textColor' | 'android_ripple'>;

const ANDROID_RIPPLE_PRIMARY = {
  color: colorTokens.surface.primaryInverseOpacity30,
  foreground: true,
};
export function PrimaryButton({ style, ...props }: ButtonProps) {
  return (
    <ButtonUI
      style={[styles.primary, style]}
      textColor={colorTokens.content.primaryInverse}
      android_ripple={ANDROID_RIPPLE_PRIMARY}
      {...props}
    />
  );
}
