import merge from 'lodash/merge';
import React from 'react';
import { defaultTheme, RaThemeOptions } from 'react-admin';
import {
  Link as RouterLink,
  LinkProps as RouterLinkProps,
} from 'react-router-dom';

export const opacity = {
  5: '0D',
  7: '12',
  10: '1A',
  15: '26',
  20: '33',
  25: '40',
  30: '4D',
  35: '59',
  40: '66',
  45: '73',
  50: '80',
  55: '8C',
  60: '99',
  65: 'A6',
  70: 'B3',
  75: 'BF',
  80: 'CC',
  85: 'D9',
  90: 'E6',
  95: 'F2',
} as const;

export const primary = {
  spruce: '#073735',
  spruce95: '#13413F',
  spruce90: '#204B49',
  spruce75: '#456967',
  spruce50: '#839B9A',
  spruce25: '#C1CDCC',
  spruce10: '#E6EBEB',
  spruce5: '#F3F5F5',
  spruce2: '#FAFBFB',
} as const;

export const secondary = {
  lilac: '#AB8BE0',
  wisteria: '#DDCAFC',
  wisteria40: '#F1EAFE',
  southSea: '#41B7AC',
  aquamarine: '#AADEDA',
  aquamarine40: '#DDF2F0',
  mandarin: '#EFA962',
  apricot: '#FFCA94',
  apricot40: '#FFEAD4',
  white: '#FFFFFF',
  cream: '#F8F2E9',
} as const;

const ui = {
  spruceTransparent7: `${primary.spruce}${opacity[7]}`,
  whiteTransparent20: `${secondary.white}${opacity[20]}`,
  whiteTransparent50: `${secondary.white}${opacity[50]}`,
  background: '#F9F4EF',
  coral: '#E87374',
  coralLight: '#F4A3A4',
  straw: '#EED996',
  hazel: '#7B5A2D',
  grey: '#D0D0D0',
} as const;

// Tokens

const semanticTokens = {
  surface: {
    primary: secondary.white,
    primaryInverseOpacity30: `${primary.spruce}${opacity[30]}`,
    primaryInverse: primary.spruce,
    secondary: ui.background,
    subtle: ui.spruceTransparent7,
    subtleInverse: ui.whiteTransparent20,
    danger: ui.coral,
    dangerLight: ui.coralLight,
    accentLilacContrast: secondary.lilac,
    accentLilac: secondary.wisteria,
    accentLilacSubtle: secondary.wisteria40,
    accentSeaContrast: secondary.southSea,
    accentSea: secondary.aquamarine,
    accentSeaSubtle: secondary.aquamarine40,
    accentMandarinContrast: secondary.mandarin,
    accentMandarin: secondary.apricot,
    accentMandarinSubtle: secondary.apricot40,
    grey: ui.grey,
  },
  content: {
    primary: primary.spruce,
    primaryInverse: secondary.white,
    secondary: primary.spruce50,
    secondaryInverse: ui.whiteTransparent50,
    danger: ui.coral,
    subtle: primary.spruce25,
    subtleBackground: primary.spruce10,
    accentLilac: secondary.lilac,
    accentSea: secondary.southSea,
    accentMandarin: secondary.mandarin,
  },
} as const;

export const colorTokens = {
  ...semanticTokens,
  primitives: {
    opacity,
    straw: ui.straw,
    hazel: ui.hazel,
    doctor: secondary.aquamarine,
    doctorDark: secondary.southSea,
    doctorLight: secondary.aquamarine40,
    nurse: secondary.apricot,
    nurseDark: secondary.mandarin,
    nurseLight: secondary.apricot40,
    midwife: secondary.wisteria,
    midwifeDark: secondary.lilac,
    midwifeLight: secondary.wisteria40,
    cream: secondary.cream,
    text: primary.spruce,
    whiteText: primary.spruce2,
  },
} as const;

const palette = merge({}, defaultTheme.palette, {
  primary: {
    main: primary.spruce,
    main75: primary.spruce75,
    main50: primary.spruce50,
    contrastText: primary.spruce2,
    subtle: primary.spruce25,
    main10: primary.spruce10,
    inverse: secondary.white,
  },
  secondary: {
    main: secondary.lilac,
    main75: secondary.wisteria,
    main50: secondary.wisteria40,
  },
  error: {
    main: ui.coral,
    subtle: ui.coralLight,
  },
  info: {
    main: primary.spruce25,
  },
  warning: {
    main: secondary.apricot,
    main75: secondary.apricot40,
  },
  success: {
    main: primary.spruce50,
  },
  text: {
    main: primary.spruce,
    white: primary.spruce2,
  },
  background: {
    grey: ui.grey,
  },
});

const LinkBehavior = React.forwardRef<
  any,
  Omit<RouterLinkProps, 'to'> & { href: RouterLinkProps['to'] }
>((props, ref) => {
  const { href, ...other } = props;
  // Map href (MUI) -> to (react-router)
  return (
    <RouterLink data-testid="custom-link" ref={ref} to={href} {...other} />
  );
});

const colorTheme: RaThemeOptions = {
  components: {
    MuiTypography: {
      styleOverrides: {
        root: {
          color: primary.spruce,
        },
      },
    },
  },
};

const rawTheme: RaThemeOptions = {
  sidebar: {
    width: 200, // The default value is 240
    closedWidth: 50, // The default value is 55
  },
  palette,
  typography: {
    fontFamily: 'Manrope, sans-serif',
    h1: {
      fontFamily: 'Lora, serif',
      fontSize: '1.75rem',
    },
  },
  components: {
    MuiAppBar: {
      styleOverrides: {
        root: {
          color: primary.spruce,
        },
      },
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          minWidth: '280px',
        },
      },
    },
    MuiTablePagination: {
      styleOverrides: {
        actions: {
          MuiButton: {
            root: {
              marginLeft: '0.5rem !important',
            },
          },
        },
      },
    },
    MuiButtonBase: {
      defaultProps: {
        LinkComponent: LinkBehavior,
      },
    },
    // To allow custom color in data-grid rows
    MuiTableCell: {
      styleOverrides: {
        root: {
          color: 'inherit',
        },
      },
    },
  },
};

export const mayTheme = merge({}, defaultTheme, rawTheme, colorTheme);

export const mayDatagridTheme = merge({}, defaultTheme, rawTheme);
