import { DiscussionEventType } from '@boTypes/discussionEvent';
import { ContentSuggestion } from '@components/discussions/chat/contentSuggestion/ContentSuggestion';
import { useSuggestions } from '@components/discussions/chat/contentSuggestion/hooks';
import { Paper } from '@mui/material';

export const ContentSuggestionPaper = ({
  subjectId,
  discussionId,
  onClose,
  onCopy,
  onSend,
}: {
  subjectId?: number;
  discussionId?: number;
  onSend: (
    content: { type: DiscussionEventType; content: string },
    contentType: string,
    contentId: number,
    fromSuggestion?: boolean,
  ) => void;
  onCopy: (
    content: string,
    macroId: number,
    suggestionId: number,
    fromSuggestion?: boolean,
  ) => void;
  onClose: () => void;
}) => {
  const {
    isLoading,
    guides,
    macro,
    posts,
    masterclasses,
    setOpenSimilarQuestionDialog,
    openSimilarQuestionDialog,
  } = useSuggestions({
    subjectId,
    open: true,
    discussionId,
  });

  return (
    <Paper
      sx={{
        '& .MuiDrawer-paper': {
          boxSizing: 'border-box',
          minWidth: 400,
          maxWidth: 500,
        },
      }}
      elevation={0}
    >
      <ContentSuggestion
        isLoading={isLoading}
        guides={guides}
        posts={posts}
        masterclasses={masterclasses}
        macro={macro}
        handleContentSend={onSend}
        handleCopyContent={onCopy}
        subjectId={subjectId}
        onClose={onClose}
        setSimilarQuestionDialog={setOpenSimilarQuestionDialog}
        openSimilarQuestionDialog={openSimilarQuestionDialog}
      />
    </Paper>
  );
};
