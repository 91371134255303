import { HandoverRevive } from '@boTypes/handover';
import styled from '@emotion/styled';
import { AccordionSummary } from '@mui/material';

import { colorTokens } from '../../themes';

export const getLimitDateColor = (limit: number) => {
  // const threshold = Date.now() + 5 * 60 * 1000; // 5min
  const threshold = Date.now();
  return limit > threshold ? undefined : colorTokens.content.danger;
};

export const getReviveColor = (revive?: HandoverRevive) => {
  if (!revive || revive.performedAt || !revive.active) {
    return undefined;
  }
  const reviveAt = new Date(revive.reviveAt).getTime();
  return getLimitDateColor(reviveAt);
};

export const AccordionSummaryWrapper = styled(AccordionSummary)({
  alignItems: 'center',
  flex: 1,
  padding: 0,
  minHeight: 0,
  height: '1.5rem',
  '&:focus::after': {
    backgroundColor: 'transparent',
  },
  '&:active:hover::after': {
    backgroundColor: 'transparent',
  },
  '& .Mui-expanded': {
    padding: 0,
    minHeight: 0,
  },
  '& .MuiAccordionSummary-content': {
    margin: 0,
    padding: 0,
    minHeight: 0,
  },
  '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
    transform: 'rotate(90deg)',
  },
});
