import { useTranslate } from 'react-admin';

import { Paper, Typography } from '@mui/material';

import { EditPasswordForm } from './editPasswordForm';

export const EditPassword = () => {
  const translate = useTranslate();

  return (
    <Paper elevation={0} sx={{ padding: '1rem', minHeight: '100%' }}>
      <Typography variant="h1" color="primary" sx={{ fontWeight: 'bold' }}>
        {translate('profile.changePassword')}
      </Typography>
      <EditPasswordForm />
    </Paper>
  );
};
