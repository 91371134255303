import dayjs from 'dayjs';
import { useMemo } from 'react';
import { useTranslate } from 'react-admin';
import { useNavigate } from 'react-router';

import { DiscussionFavorite } from '@boTypes/discussionFavorite';
import { Loader } from '@components/Loader';
import { getLastMessage } from '@components/subjects/utils';
import { useMutation, useQuery } from '@hooks/queryWrappers';
import LaunchIcon from '@mui/icons-material/Launch';
import { Button, Paper, Typography } from '@mui/material';
import { DataGrid, GridColDef } from '@mui/x-data-grid';

export const Favorites = () => {
  const navigate = useNavigate();
  const translate = useTranslate();
  const { data: favorites, isLoading } = useQuery<DiscussionFavorite[]>(
    ['discussion-favorites'],
    () => ({
      method: 'GET',
      url: '/api/discussion-favorites',
    }),
  );

  const { mutateAsync: updateFavorite } = useMutation<
    any,
    any,
    { comments: string; id: number }
  >(['updateFavorite'], (data) => {
    const { id, ...rest } = data;
    return {
      method: 'PUT',
      url: `/api/discussion-favorites/${id}`,
      data: rest,
    };
  });

  const columns = useMemo(
    (): GridColDef[] => [
      {
        field: 'discussionId',
        headerName: 'Id',
        flex: 1,
        editable: false,
      },
      {
        field: 'firstName',
        headerName: translate('common.firstName'),
        flex: 1,
        editable: false,
        renderCell: (params) =>
          params.row.discussion.child
            ? params.row.discussion.child.firstName
            : params.row.discussion.appUser.firstName,
      },
      {
        field: 'createdAt',
        headerName: translate('common.creationDate'),
        editable: false,
        flex: 1,
        valueGetter: (value: string) =>
          dayjs(value).tz('Europe/Paris').format('DD/MM/YY HH:mm'),
      },
      {
        field: 'lastEvent',
        headerName: translate('favorites.lastMessage'),
        editable: false,
        flex: 1,
        valueGetter: (_value: never, row: DiscussionFavorite) =>
          getLastMessage(row.discussion.lastEvent),
      },
      {
        field: 'comments',
        headerName: translate('favorites.comments'),
        editable: true,
        flex: 2,
      },
      {
        field: 'goToDiscussion',
        headerName: translate('common.discussion'),
        flex: 1,
        renderCell: (params) => (
          <Button
            onClick={() => {
              if (!params.row.discussion.kidId) {
                navigate(`/midwifesubjects/${params.row.discussion.id}`);
              } else {
                navigate(`/nursesubjects/${params.row.discussion.id}`);
              }
            }}
            variant="outlined"
            startIcon={<LaunchIcon />}
          >
            {translate('common.see')}
          </Button>
        ),
      },
    ],
    [navigate, translate],
  );

  if (isLoading) {
    return (
      <Paper
        sx={{
          width: '100%',
          height: '100%',
          padding: 1,
        }}
      >
        <Loader />
      </Paper>
    );
  }

  if (!favorites.length) {
    return (
      <Paper
        sx={{
          width: '100%',
          height: '100%',
          padding: 1,
        }}
      >
        {translate('favorites.none')}
      </Paper>
    );
  }

  return (
    <Paper elevation={0} sx={{ width: '100%', padding: 1, display: 'grid' }}>
      <Typography
        variant="h1"
        color="primary"
        sx={{ fontWeight: 'bold', marginBottom: 1 }}
      >
        {translate('favorites.title')}
      </Typography>
      <DataGrid
        rows={favorites}
        columns={columns}
        disableColumnFilter
        isRowSelectable={() => false}
        processRowUpdate={(updatedRow, originalRow) => {
          return (
            updatedRow.comments !== originalRow.comments &&
            updateFavorite(updatedRow)
          );
        }}
        disableColumnMenu
      />
    </Paper>
  );
};
