import { AttributionUIDiscussionMainFilters } from './attributionUIQuickFilters';
import { Discussion } from '../../types';
import {
  AttributionFilter,
  DiscussionQuickFilter,
} from '../../types/discussion';

export const DiscussionMainFilters = ({
  discussions = [],
  allDiscussions = [],
  onFilterClick,
  filterValue,
  onAttribFilterClick,
  attribFilterValue,
}: {
  discussions: Discussion[];
  allDiscussions: Discussion[];
  onFilterClick: (filter: DiscussionQuickFilter) => void;
  filterValue: DiscussionQuickFilter;
  onAttribFilterClick: (filter: AttributionFilter) => void;
  attribFilterValue: AttributionFilter;
}) => {
  return (
    <AttributionUIDiscussionMainFilters
      discussions={discussions}
      allDiscussions={allDiscussions}
      onFilterClick={onFilterClick}
      filterValue={filterValue}
      onAttribFilterClick={onAttribFilterClick}
      attribFilterValue={attribFilterValue}
    />
  );
};
