import sortBy from 'lodash/sortBy';
import { ChangeEvent } from 'react';
import { useTranslate } from 'react-admin';

import { StaffUser } from '@boTypes/staffUser';
import { Check } from '@mui/icons-material';
import {
  Box,
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  List,
  Paper,
  Switch,
  Typography,
} from '@mui/material';

const styles = {
  paper: {
    padding: '1rem',
  },
};

interface ExtendedStaffUser extends StaffUser {
  hasSeen?: boolean;
  color?: string;
}

export const DisplaySelector = ({
  staffUsers,
  displayedUsers,
  setDisplayedUsers,
  displayUnattributed,
  setDisplayUnattributed,
}: {
  staffUsers: Record<StaffUser['id'], ExtendedStaffUser>;
  displayedUsers: Record<StaffUser['id'], boolean>;
  setDisplayedUsers: (userId: StaffUser['id'], value: boolean) => void;
  displayUnattributed: boolean;
  setDisplayUnattributed: (value: boolean) => void;
}) => {
  const translate = useTranslate();

  return (
    <Paper elevation={0} style={styles.paper}>
      <FormControl fullWidth>
        <FormControlLabel
          control={
            <Switch
              inputProps={{
                'aria-label': translate(
                  'planning.displaySelector.displayUnattributed',
                ),
              }}
              checked={displayUnattributed}
              onChange={(ev: ChangeEvent<HTMLInputElement>) =>
                setDisplayUnattributed(ev.target.checked)
              }
            />
          }
          label={translate('planning.displaySelector.displayUnattributed')}
        />

        <Box sx={{ display: 'flex', alignItems: 'center', flex: 0 }}>
          <Button
            onClick={() =>
              Object.values(staffUsers).forEach(
                (su) =>
                  !displayedUsers[su.id] && setDisplayedUsers(su.id, true),
              )
            }
          >
            {translate('common.selectAll')}
          </Button>
          {'/'}
          <Button
            onClick={() =>
              Object.values(staffUsers).forEach(
                (su) =>
                  displayedUsers[su.id] && setDisplayedUsers(su.id, false),
              )
            }
          >
            {translate('common.unselectAll')}
          </Button>
        </Box>
        <List sx={{ flex: 1, overflowY: 'auto' }}>
          {sortBy(Object.values(staffUsers), (su) =>
            su.fullName.toLowerCase(),
          ).map((resource) => (
            <Button
              key={resource.id}
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                m: 1,
                p: 0,
                width: 'calc(100% - 3rem)',
              }}
              onClick={() =>
                setDisplayedUsers(resource.id, !displayedUsers[resource.id])
              }
            >
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'row',
                }}
              >
                <Checkbox
                  sx={{
                    p: 0,
                    marginLeft: 0.5,
                    marginRight: 1,
                    color: resource.color,
                    '&.Mui-checked': {
                      color: resource.color,
                    },
                  }}
                  checked={displayedUsers[resource.id]}
                />
                <Typography textTransform={'none'}>
                  {resource.fullName}
                </Typography>
              </Box>
              {resource.hasSeen && (
                <Box sx={{ display: 'flex', alignItems: 'bottom' }}>
                  <Check sx={{ fontSize: '1.25rem' }} />
                  <Typography
                    variant="caption"
                    sx={{
                      fontSize: '0.75rem',
                      color: 'background.grey',
                    }}
                  >
                    {translate('common.seen')}
                  </Typography>
                </Box>
              )}
            </Button>
          ))}
        </List>
      </FormControl>
    </Paper>
  );
};
