import { useTranslate } from 'react-admin';

import { DiscussionEventType } from '@boTypes/discussionEvent';
import { Typography } from '@mui/material';

import { PostItem, HEIGHT, WIDTH } from './PostItem';
import { Post } from '../../types';
import { FullWidthList, useWrappedItemWithSend } from '../fullWidthList';

export const PostsList = ({
  list = [],
  onSend,
  fromSuggestion,
  subjectId,
}: {
  list: Post[];
  onSend?: (
    { type, content }: { type: DiscussionEventType; content: string },
    contentType: string,
    contentId: number,
  ) => void;
  fromSuggestion?: boolean;
  subjectId?: number;
}) => {
  const translate = useTranslate();

  const Item = useWrappedItemWithSend(
    PostItem,
    onSend,
    fromSuggestion,
    subjectId,
  );

  if (!list || list.length === 0) {
    return null;
  }
  return (
    <>
      <Typography variant="subtitle2" color="primary">
        {translate('library.posts')}
      </Typography>
      <FullWidthList
        itemCount={list.length}
        itemSize={WIDTH + 16}
        height={HEIGHT + 20 + (onSend ? 18 : 0)}
        layout="horizontal"
        itemData={list}
      >
        {Item}
      </FullWidthList>
    </>
  );
};
