import { StyleSheet, View, Text } from 'react-native';

import { PressableOpacity } from '@components/PressableOpacity';
import { formatTZ } from '@utils/date';

import { NextButton, PreviousButton } from './Actions';
import { colorTokens } from '../../../../themes';
import type { WeekTuple } from '../types';

export const WEEK_SELECTOR_WIDTH = 240;
const styles = StyleSheet.create({
  container: {
    width: WEEK_SELECTOR_WIDTH,
    flexDirection: 'row',
    alignItems: 'center',
    gap: 8,
  },
  label: {
    color: colorTokens.content.primary,
  },
});

interface WeekSelectorProps {
  week: WeekTuple;
  onNext: () => void;
  onPrevious: () => void;
  disableNext?: boolean;
  disablePrevious?: boolean;
  onPress?: () => void;
}
const WeekSelector = ({
  week,
  onNext,
  onPrevious,
  disablePrevious,
  disableNext,
  onPress,
}: WeekSelectorProps) => (
  <View style={styles.container}>
    <PreviousButton onPress={onPrevious} disabled={disablePrevious} />
    <PressableOpacity onPress={onPress} disabled={!onPress}>
      <Text style={styles.label}>
        {formatTZ(week[0], 'DD MMM')} - {formatTZ(week[6], 'DD MMM')}
      </Text>
    </PressableOpacity>
    <NextButton onPress={onNext} disabled={disableNext} />
  </View>
);

export default WeekSelector;
