import { useCallback } from 'react';
import {
  ArrayField,
  AutocompleteInput,
  BooleanField,
  Datagrid,
  Filter,
  ReferenceField,
  ReferenceInput,
  SingleFieldList,
  TextField,
  TextInput,
  useRecordContext,
  useTranslate,
} from 'react-admin';
import { useNavigate } from 'react-router-dom';

import { Discussion } from '@boTypes';
import { useMutation } from '@hooks/queryWrappers';
import { Search } from '@mui/icons-material';
import { Button } from '@mui/material';

import { AgeField, FullNameField } from '../../components/fields';
import { List } from '../../components/generic/List';

const ChildrenFilter = (props) => {
  const translate = useTranslate();
  return (
    <Filter {...props}>
      <TextInput
        label={translate('common.filterByName')}
        autoFocus
        InputProps={{
          startAdornment: <Search sx={{ color: 'background.grey' }} />,
        }}
        placeholder="Arthie"
        source="firstName"
        alwaysOn
        variant="standard"
      />
      <ReferenceInput source="appUser" reference="appUsers" alwaysOn>
        <AutocompleteInput
          label={translate('common.parents')}
          variant="standard"
          optionText={(record) =>
            record?.name ? `${record.name} (${record.email})` : '---'
          }
        />
      </ReferenceInput>
    </Filter>
  );
};

const GoToDiscussionField = () => {
  const navigate = useNavigate();
  const record = useRecordContext();
  const t = useTranslate();

  const { id } = record || {}; // record can be undefined
  const { mutateAsync: getDiscussion } = useMutation<Discussion, any, void>(
    ['kidDiscussion', id],
    () => ({
      url: `/api/discussions/fromKid/${id}`,
      method: 'GET',
    }),
  );

  const goToDiscussionPage = useCallback(
    async (e) => {
      e.stopPropagation();
      if (!id) {
        return;
      }
      const discussion = await getDiscussion();
      navigate(`/nursesubjects/${discussion.id}`);
    },
    [id, getDiscussion, navigate],
  );

  return (
    <Button size="small" onClick={goToDiscussionPage}>
      {t('common.goToDiscussion')}
    </Button>
  );
};

export const ChildrenList = () => {
  const translate = useTranslate();
  return (
    <List
      filters={<ChildrenFilter />}
      sx={{ minHeight: '100%', padding: '0 1rem' }}
    >
      <Datagrid bulkActionButtons={false}>
        <TextField source="id" />
        <FullNameField label={translate('common.name')} />
        <AgeField source="birthDay" label={translate('common.age')} />
        <ReferenceField
          source="family.id"
          reference="family"
          label={translate('common.parents')}
          link={false}
        >
          <ArrayField source="appUsers">
            <SingleFieldList linkType={false}>
              <TextField source="firstName" sx={{ marginRight: '0.5rem' }} />
            </SingleFieldList>
          </ArrayField>
        </ReferenceField>
        <BooleanField source="active" label={translate('common.active')} />
        <GoToDiscussionField />
      </Datagrid>
    </List>
  );
};
