import React from 'react';
import { useTranslate } from 'react-admin';

import CloseIcon from '@mui/icons-material/Close';
import { Dialog, DialogContent, Typography } from '@mui/material';
import AppBar from '@mui/material/AppBar';
import IconButton from '@mui/material/IconButton';
import Slide from '@mui/material/Slide';
import Toolbar from '@mui/material/Toolbar';
import { TransitionProps } from '@mui/material/transitions';

import { SimilarQuestion } from '.';

const appBarStyles = {
  height: '48px',
  minHeight: '48px',
  marginBottom: '0',
};

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & { children: React.ReactElement },
  ref: React.Ref<unknown>,
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export const SimilarQuestionDialog = ({
  opened,
  close,
  subjectId,
}: {
  opened: boolean;
  close: () => void;
  subjectId: number;
}) => {
  const translate = useTranslate();

  if (!opened) {
    return null;
  }

  return (
    <Dialog
      fullScreen
      open={opened}
      onClose={close}
      TransitionComponent={Transition}
    >
      <AppBar
        sx={{
          position: 'relative',
        }}
        style={appBarStyles}
      >
        <Toolbar variant="dense">
          <IconButton
            edge="start"
            color="inherit"
            onClick={close}
            aria-label="close"
            size="large"
            sx={{ color: 'text.white' }}
          >
            <CloseIcon />
          </IconButton>

          <Typography sx={{ color: 'text.white' }}>
            {translate('similarQuestion.title')}
          </Typography>
        </Toolbar>
      </AppBar>
      <DialogContent
        sx={{
          p: 0,
          width: '100%',
          height: '100%',
        }}
      >
        <SimilarQuestion currentSubjectId={subjectId} />
      </DialogContent>
    </Dialog>
  );
};
