import { PressableProps, StyleProp, StyleSheet, ViewStyle } from 'react-native';

import { PressableNative } from './PressableNative';
import { colorTokens } from '../../../../../themes';
import { Arrow } from '../SVG/arrow';

interface BackButtonProps {
  style?: StyleProp<ViewStyle>;
  onPress?: () => void;
  testID?: string;
}

const styles = StyleSheet.create({
  container: {
    height: 40,
    aspectRatio: 1,
    borderRadius: 20,
    backgroundColor: colorTokens.surface.primary,
    alignItems: 'center',
    justifyContent: 'center',
  },
});

interface ActionsProps extends PressableProps {
  style?: StyleProp<ViewStyle>;
  iconColor?: string;
  size?: number;
}
const Actions = ({ style, children, ...pressableProps }: ActionsProps) => (
  <PressableNative style={[styles.container, style]} {...pressableProps}>
    {children}
  </PressableNative>
);

export const BackButton = ({ style, onPress, testID }: BackButtonProps) => {
  return (
    <Actions style={style} onPress={onPress} testID={testID}>
      <Arrow color={colorTokens.content.primary} />
    </Actions>
  );
};
