import Check from '@mui/icons-material/Check';
import { Box } from '@mui/material';
import { styled } from '@mui/system';

import { colorTokens } from '../../../../themes';
import { LiviIcon } from '../liviIcon';

const whiteColor = { color: colorTokens.content.primaryInverse };

const _AppIcon = ({ name }) => {
  switch (name) {
    case 'LiviText':
      return <LiviIcon />;
    case 'Arrow':
      // We could use the ArrowForwardIos icon from MUI here
      // BUT... it's does not make much sense to have > in livi card and form card
      // So we're removing it
      return null;
    case 'CheckmarkSlim':
      return (
        <Check
          style={whiteColor}
          sx={{
            height: '100%',
            width: '100%',
          }}
        />
      );
    default:
      return null;
  }
};

const StyledBox = styled(Box)(({ size }: { size: number }) => ({
  width: `${size}px`,
  height: `${size}px`,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}));

export const AppIcon = ({ name, size }: { name: string; size: number }) => {
  return (
    <StyledBox size={size}>
      <_AppIcon name={name} />
    </StyledBox>
  );
};
