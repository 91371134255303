import minBy from 'lodash/minBy';
import React, { useCallback, useMemo } from 'react';
import { useTranslate } from 'react-admin';
import { StyleSheet, Text, View } from 'react-native';
import { useDispatch } from 'react-redux';

import { Discussion } from '@boTypes/discussion';
import { DiscussionEventType } from '@boTypes/discussionEvent';
import { HandoverRevive } from '@boTypes/handover';
import { getIdsInfoFromDiscussion } from '@components/subjects/utils';
import { useChatFormList } from '@hooks/form-builder';
import { usePatientHistory } from '@hooks/history';
import { EmojiEmotions, MoreHoriz } from '@mui/icons-material';
import AccessAlarmIcon from '@mui/icons-material/AccessAlarm';
import MenuBook from '@mui/icons-material/MenuBook';
import { Button, IconButton, Tooltip, Typography } from '@mui/material';
import { Menu } from '@mui/material';

import { LiviIcon } from './liviIcon';
import {
  logContentSendFromLibrary,
  logContentSuggestionMacroSuggestions,
  logContentSuggestionSend,
  logLibraryFromChat,
  logMacroSuggestionFromLibrary,
} from '../../../analytics/events';
import { ContentDialog } from '../../../entities/library/dialog';
import { setMacroSuggestion } from '../../../store/macroSuggestion';
import { DialogButton } from '../../DialogButton/DialogButton';
import { PressableOpacity } from '../../PressableOpacity';

const actionStyles = StyleSheet.create({
  container: {
    width: 32,
    display: 'flex',
    gap: 4,
    flexDirection: 'column',
    alignItems: 'center',
    paddingTop: 8,
  },
  waveIcon: {
    width: 24,
    fontSize: 20,
  },
  liviIcon: {
    height: 24,
    width: 32,
    marginLeft: 4,
    marginTop: 4,
  },
  reviveIcon: {
    width: 26,
    height: 26,
  },
});

export const ChatActions = ({
  createLiviConsultationMessage,
  onContentSend,
  onContentCopy,
  introduction,
  discussion,
  setRevive,
  contentDialogOpened,
  setContentDialogOpened,
  setOpenEmojiPicker,
}: {
  onContentSend: (arg: { type: DiscussionEventType; content: string }) => void;
  onContentCopy: (content: string) => void;
  introduction: () => void;
  createLiviConsultationMessage?: () => void;
  discussion: Discussion;
  setRevive: (revive: HandoverRevive) => void;
  contentDialogOpened: boolean;
  setContentDialogOpened: (arg: boolean) => void;
  setOpenEmojiPicker: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
  const { data } = useChatFormList({ enabled: false });
  const { revives } = usePatientHistory(discussion);
  const revive = useMemo(
    () =>
      minBy(revives.map((r) => r.revive) || [], (r) =>
        new Date(r.reviveAt).getTime(),
      ),
    [revives],
  );
  const LiviButton = useCallback(({ onClick }: any) => {
    return (
      <PressableOpacity
        onPress={(ev) => onClick(ev)}
        style={actionStyles.liviIcon}
      >
        <LiviIcon color={'primary.main50'} />
      </PressableOpacity>
    );
  }, []);

  const handleCloseContentDialog = useCallback(() => {
    setContentDialogOpened(false);
  }, [setContentDialogOpened]);

  const handleSendContent = useCallback(
    (
      arg: { type: DiscussionEventType; content: string },
      type: string,
      contentId: number,
      fromSuggestion?: boolean,
    ) => {
      onContentSend(arg);
      if (fromSuggestion) {
        logContentSuggestionSend(discussion?.lastSubjectId, type, contentId);
      } else {
        logContentSendFromLibrary(discussion?.lastSubjectId, type, contentId);
      }
      setContentDialogOpened(false);
    },
    [onContentSend, setContentDialogOpened, discussion?.lastSubjectId],
  );
  const dispatch = useDispatch();
  const handleCopyContent = useCallback(
    (
      arg: string,
      macroId: number,
      suggestionId: number,
      fromSuggestion?: boolean,
    ) => {
      onContentCopy(arg);
      dispatch(
        setMacroSuggestion({ macroId, macroSuggestionId: suggestionId }),
      );
      if (fromSuggestion) {
        logContentSuggestionMacroSuggestions(
          discussion?.lastSubjectId,
          suggestionId,
          macroId,
        );
      } else {
        logMacroSuggestionFromLibrary(
          discussion?.lastSubjectId,
          macroId,
          suggestionId,
        );
      }
      setContentDialogOpened(false);
    },
    [
      onContentCopy,
      setContentDialogOpened,
      discussion?.lastSubjectId,
      dispatch,
    ],
  );

  const { appUserId, familyId, childId } = getIdsInfoFromDiscussion(discussion);
  const translate = useTranslate();
  const [anchorMoreEl, setAnchorMoreEl] = React.useState<null | HTMLElement>(
    null,
  );
  const handleMoreClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorMoreEl(event.currentTarget);
  };
  const handleMoreClose = () => {
    setAnchorMoreEl(null);
  };

  return (
    <>
      <View style={actionStyles.container}>
        <PressableOpacity
          onPress={() => {
            introduction();
          }}
        >
          <Text style={actionStyles.waveIcon}>👋</Text>
        </PressableOpacity>
        <PressableOpacity
          onPress={() => {
            logLibraryFromChat(discussion.lastSubjectId);
            setContentDialogOpened(true);
          }}
        >
          <MenuBook sx={{ color: 'primary.main50' }} />
        </PressableOpacity>
        <PressableOpacity
          onPress={() => {
            setOpenEmojiPicker((prev) => !prev);
          }}
        >
          <EmojiEmotions sx={{ color: 'primary.main50' }} />
        </PressableOpacity>
        <ContentDialog
          discussionId={discussion?.id}
          appUserId={appUserId}
          childId={childId}
          familyId={familyId}
          subjectId={discussion?.lastSubjectId}
          opened={contentDialogOpened}
          close={handleCloseContentDialog}
          onSend={handleSendContent}
          onCopy={handleCopyContent}
        />
        {revive && (
          <PressableOpacity
            onPress={() => {
              setRevive(revive);
            }}
            style={actionStyles.reviveIcon}
          >
            <AccessAlarmIcon sx={{ color: 'primary.main50' }} />
          </PressableOpacity>
        )}
        <Tooltip title={translate('common.more')}>
          <IconButton
            onClick={handleMoreClick}
            sx={{ width: '1.5rem', height: '1.5rem', color: 'primary.main50' }}
            aria-controls={open ? 'more-menu' : undefined}
            aria-haspopup="true"
            aria-expanded={open ? 'true' : undefined}
          >
            <MoreHoriz />
          </IconButton>
        </Tooltip>
        <Menu
          anchorEl={anchorMoreEl}
          open={Boolean(anchorMoreEl)}
          onClose={handleMoreClose}
        >
          {data?.length > 0 && [
            <Typography
              key="title-form-send"
              variant="body2"
              sx={{ margin: '0 0.5rem' }}
            >
              {translate('forms.send')}
            </Typography>,
            data.map((item) => (
              <Button
                variant="text"
                key={item.id}
                onClick={() => {
                  handleMoreClose();
                  onContentSend({
                    type: DiscussionEventType.FORM,
                    content: item.id,
                  });
                }}
              >
                {item.title}
              </Button>
            )),
          ]}
          {createLiviConsultationMessage && [
            <Typography
              key="title-common-partners"
              variant="body2"
              sx={{ margin: '0 0.5rem' }}
            >
              {translate('common.partners')}
            </Typography>,
            <DialogButton
              key="button-livi"
              onClick={() => {
                handleMoreClose();
                createLiviConsultationMessage();
              }}
              size="small"
              title="Livi"
              text={translate('common.partnersSendLivi')}
              component={LiviButton}
            />,
          ]}
        </Menu>
      </View>
    </>
  );
};
