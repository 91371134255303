import React from 'react';

import { DiscussionEventType } from '@boTypes/discussionEvent';
import { ChatHeader } from '@components/subjects/patientHeader';
import CloseIcon from '@mui/icons-material/Close';
import { Dialog, DialogContent } from '@mui/material';
import AppBar from '@mui/material/AppBar';
import IconButton from '@mui/material/IconButton';
import Slide from '@mui/material/Slide';
import Toolbar from '@mui/material/Toolbar';
import { TransitionProps } from '@mui/material/transitions';

import { LibraryList } from './library';

const appBarStyles = {
  height: '48px',
  minHeight: '48px',
  marginBottom: '0.75rem',
};

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & { children: React.ReactElement },
  ref: React.Ref<unknown>,
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export const ContentDialog = ({
  opened,
  close,
  onSend,
  onCopy,
  discussionId,
  appUserId,
  childId,
  familyId,
  subjectId,
}: {
  opened: boolean;
  close: () => void;
  onSend: (
    content: { type: DiscussionEventType; content: string },
    contentType: string,
    contentId: number,
    fromSuggestion?: boolean,
  ) => void;
  onCopy: (
    content: string,
    macroId: number,
    suggestionId: number,
    fromSuggestion?: boolean,
  ) => void;
  discussionId?: number;
  appUserId?: number;
  childId?: number;
  familyId: string;
  subjectId?: number;
}) => {
  if (!opened) {
    return null;
  }

  return (
    <Dialog
      fullScreen
      open={opened}
      onClose={close}
      TransitionComponent={Transition}
    >
      <AppBar
        sx={{
          position: 'relative',
        }}
        style={appBarStyles}
      >
        <Toolbar variant="dense">
          <IconButton
            edge="start"
            color="inherit"
            onClick={close}
            aria-label="close"
            size="large"
            sx={{ color: 'text.white' }}
          >
            <CloseIcon />
          </IconButton>

          <ChatHeader
            appUserId={appUserId}
            familyId={familyId}
            childId={childId}
            sx={{
              flex: 1,
              boxShadow: 'initial',
              border: 'none',
              color: 'white',
            }}
          />
        </Toolbar>
      </AppBar>
      <DialogContent
        sx={{
          p: 0,
          width: '100%',
          height: '100%',
        }}
      >
        <LibraryList
          onSend={onSend}
          onCopy={onCopy}
          discussionId={discussionId}
          subjectId={subjectId}
          onClose={close}
        />
      </DialogContent>
    </Dialog>
  );
};
