import { ToggleButton, ToggleButtonGroup } from '@mui/material';
import { styled } from '@mui/material/styles';

const StyledToggleButtonGroup = styled(ToggleButtonGroup)(() => ({
  '& .MuiToggleButtonGroup-grouped': {
    borderColor: 'primary.main10',
  },
}));

export function ToggleButtons<KeyType extends string | number | symbol>({
  value,
  onChange,
  options,
}: {
  value: string;
  onChange: (arg0: KeyType) => void;
  options: Record<KeyType, string>;
}) {
  return (
    <StyledToggleButtonGroup
      exclusive
      aria-labelledby="segmented-controls-example"
      value={value}
      onChange={(_, newValue) => onChange(newValue)}
      size="small"
      color="primary"
    >
      {(Object.entries(options) as [string, string][]).map(([key, label]) => (
        <ToggleButton
          value={key}
          key={key}
          sx={{ paddingLeft: 2, paddingRight: 2, fontSize: '14px' }}
        >
          {label}
        </ToggleButton>
      ))}
    </StyledToggleButtonGroup>
  );
}
