import sortBy from 'lodash/sortBy';
import { useEffect } from 'react';
import { useTranslate } from 'react-admin';
import ReactMarkdown from 'react-markdown';
import { useParams } from 'react-router';

import { Box, Paper, Typography } from '@mui/material';

import { useChangelog, useReadChangelog } from './common/changelog.hook';
import { LinkedContentListItem } from '../components/LinkedContent/LinkedContent';

export const Changelog = () => {
  const { id } = useParams<{ id: string }>();
  const { mutate: readChangelog } = useReadChangelog();
  const translate = useTranslate();

  const { changelog } = useChangelog(id);

  useEffect(() => {
    if (changelog && !changelog?.read) {
      readChangelog(changelog.id);
    }
  }, [changelog, readChangelog]);

  if (!changelog) {
    return null;
  }

  return (
    <Paper
      sx={{
        minHeight: '100%',
        height: '100%',
        padding: '1rem',
      }}
      elevation={0}
    >
      <Typography variant="h1" color="primary" sx={{ fontWeight: 'bold' }}>
        {changelog?.title}
      </Typography>
      <Typography variant="h6">
        {new Date(changelog?.publishedAt).toLocaleDateString('fr-FR', {
          day: 'numeric',
          month: 'long',
          year: 'numeric',
        })}
      </Typography>
      <Typography variant="subtitle2">{changelog?.author}</Typography>
      <ReactMarkdown>{changelog?.content}</ReactMarkdown>

      {changelog?.linkedContent?.length ? (
        <Box sx={{ paddingBottom: '1rem' }}>
          <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
            {translate('changelog.contents')}
          </Typography>
          <Box
            sx={{
              display: 'flex',
              flexWrap: 'wrap',
              gap: 2,
              paddingTop: '1rem',
            }}
          >
            {sortBy(changelog.linkedContent, ['__component']).map(
              (linkedContent) => (
                <LinkedContentListItem
                  key={`${linkedContent.__component}-${linkedContent.id}`}
                  item={linkedContent}
                />
              ),
            )}
          </Box>
        </Box>
      ) : null}
    </Paper>
  );
};
