import { MouseEvent } from 'react';
import { useRecordContext } from 'react-admin';

import { Chip, ChipProps } from '@mui/material';

import { colorTokens } from '../../themes';
import { Category } from '../../types';

type ChipCategory = Pick<Category, 'pregnancy' | 'slug' | 'name'> & {
  metaCategory?: Category['metaCategory'];
};

export const categoryColors: Record<string, string> = {
  'blue-light': colorTokens.surface.accentLilacSubtle,
  'blue-dark': colorTokens.surface.accentLilac,
  'green-light': colorTokens.surface.accentSeaSubtle,
  'green-dark': colorTokens.surface.accentSea,
  'yellow-light': colorTokens.surface.accentMandarinSubtle,
  'yellow-dark': colorTokens.surface.accentMandarin,
  'red-light': colorTokens.surface.dangerLight,
  'red-dark': colorTokens.surface.danger,
  default: colorTokens.surface.grey,
};

export const categoryOrders: Record<string, string> = {
  'blue-dark': '1',
  'blue-light': '2',
  'red-dark': '3',
  'red-light': '4',
  'green-dark': '5',
  'green-light': '6',
  'yellow-dark': '7',
  'yellow-light': '8',
  default: '9',
};

interface CategoriesChipFieldProps {
  label?: string;
  size?: 'small' | 'medium';
}

export const getCategoryColor = (category: ChipCategory) =>
  categoryColors[category.metaCategory || 'default'];

const getCategoryStyle = (category: ChipCategory, isValidated: boolean) => {
  const backgroundColor = getCategoryColor(category);
  return {
    backgroundColor,
    borderWidth: isValidated ? 0 : 2,
    borderStyle: 'dashed',
    borderColor: isValidated ? 'transparent' : 'primary.main10',
  };
};

export const CategoryChip = ({
  category,
  size,
  isValidated = true,
  onClick = () => {},
  ...props
}: {
  category?: ChipCategory;
  size?: 'small' | 'medium';
  isValidated?: boolean;
  onClick?: (clickEvent: MouseEvent<HTMLElement>) => void;
} & ChipProps) => {
  if (!category) {
    return null;
  }
  const categoryStyle = getCategoryStyle(category, isValidated);
  return (
    <Chip
      {...props}
      label={category.name}
      sx={{ ...categoryStyle, ...props.sx }}
      size={size}
      onClick={onClick}
    />
  );
};

export const CategoriesChipField = ({ size }: CategoriesChipFieldProps) => {
  const record = useRecordContext<Category>();
  return record ? <CategoryChip category={record} size={size} /> : null;
};
