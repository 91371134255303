import { Guide } from '@boTypes/guide';
import { Macro } from '@boTypes/macroType';
import { Masterclass } from '@boTypes/masterclass';
import { Post } from '@boTypes/post';

export type Content = Post | Guide | Masterclass | Macro;

export interface Suggestion {
  id: number;
}

export enum SuggestionContentType {
  GUIDES = 'guides',
  POSTS = 'posts',
  MACRO = 'macro',
  MASTERCLASSES = 'masterclasses',
}
