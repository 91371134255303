import { useTranslate } from 'react-admin';

import {
  Box,
  BoxProps,
  LinearProgress as MuiLinearProgress,
  Typography,
  TypographyProps,
  styled,
} from '@mui/material';

import { colorTokens } from '../../../themes';
import { PlanningStatus, statusToString } from '../../../types/planning';

const bubbleHeight = 18;
const barHeight = 4;
const smallBubbleHeight = 14;
const smallBubbleTop = (bubbleHeight - smallBubbleHeight) / 2;
const scaleFactor = 1.375;

const ItemLabel = styled(
  ({ children, active, ...props }: TypographyProps & { active: boolean }) => (
    <Typography {...props}>{children}</Typography>
  ),
  // @ts-ignore
  { shouldForwardProp: (prop) => prop !== 'active' },
)(({ active = false }: { active?: boolean }) => ({
  fontSize: 10,
  paddingTop: active ? '56px' : '52px',
  lineHeight: '12px',
  fontWeight: active ? 600 : 300,
  color: active ? 'primary.main75' : 'primary.main50',
}));

const Dot = styled(
  ({
    label,
    active,
    ...props
  }: BoxProps & {
    active: boolean;
    label: string;
    progress: number;
    done: boolean;
  }) => (
    <Box {...props}>
      <ItemLabel active={active}>{label}</ItemLabel>
    </Box>
  ),
  {
    shouldForwardProp: (prop) =>
      prop !== 'progress' && prop !== 'active' && prop !== 'done',
  },
)(
  ({
    progress = 0,
    active = false,
    done = false,
  }: {
    progress?: number;
    active?: boolean;
    done?: boolean;
  }) => ({
    position: 'absolute',
    left: `calc(${progress}% - ${
      ((active ? scaleFactor : 1) * smallBubbleHeight) / 2
    }px)`,
    top: active ? '-1px' : smallBubbleTop,
    backgroundColor: done
      ? colorTokens.content.secondary
      : colorTokens.content.subtle,
    width: (active ? scaleFactor : 1) * smallBubbleHeight,
    height: (active ? scaleFactor : 1) * smallBubbleHeight,
    borderRadius: '50%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center',
  }),
);

export const LinearProgress = ({
  list,
  active,
}: {
  list: PlanningStatus[];
  active: PlanningStatus;
}) => {
  const translate = useTranslate();
  const activeIndex = list.indexOf(active);
  return (
    <Box
      sx={{
        position: 'relative',
        height: bubbleHeight * 2,
        marginLeft: `${bubbleHeight / 2}px`,
        marginRight: `${bubbleHeight / 2}px`,
        marginBottom: 3,
      }}
    >
      <MuiLinearProgress
        variant="determinate"
        value={(100 * activeIndex) / (list.length - 1)}
        color="inherit"
        sx={{
          position: 'absolute',
          top: (bubbleHeight - barHeight) / 2,
          height: barHeight,
          width: '100%',
          color: 'primary.main50',
        }}
      />
      {list.map((status, i) => (
        <Dot
          key={`dot-${i}`}
          progress={(100 * i) / (list.length - 1)}
          done={i <= activeIndex}
          active={status === active}
          label={translate(statusToString[status])}
        />
      ))}
    </Box>
  );
};
