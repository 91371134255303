import { memo } from 'react';
import { dayjsLocalizer } from 'react-big-calendar';
import {
  views as Views,
  navigate,
} from 'react-big-calendar/lib/utils/constants';

import { StaffUser } from '@boTypes/staffUser';
import { List } from '@mui/icons-material';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import LanguageIcon from '@mui/icons-material/Language';
import {
  Badge,
  Box,
  Button,
  ButtonGroup,
  IconButton,
  Typography,
} from '@mui/material';

import { colorTokens } from '../../themes';

const styles = {
  toolbar: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: '1rem',
  },
  surrounding: {
    flex: 0,
  },
  label: {
    flex: 1,
    paddingLeft: '1rem',
    color: colorTokens.content.secondary,
    fontSize: '0.825rem',
  },
  buttonGroup: {
    color: colorTokens.content.secondary,
    borderColor: colorTokens.content.subtleBackground,
  },
  active: {
    color: colorTokens.content.primary,
    backgroundColor: colorTokens.content.subtleBackground,
  },
  timezoneSelect: {
    marginLeft: '1rem',
  },
};

const Toolbar = ({
  view,
  views,
  onView,
  onNavigate,
  localizer,
  label,
  displaySelector = false,
  displayedUsers,
  staffUsers,
  setDisplayDrawer,
  setTimezoneDrawer,
}: {
  view: Views[keyof Views];
  views: Views;
  onView: (view: Views[keyof Views]) => void;
  onNavigate: (action: string) => void;
  label: string;
  localizer: dayjsLocalizer;
  staffUsers: Record<StaffUser['id'], StaffUser>;
  displaySelector?: boolean;
  displayedUsers?: Record<StaffUser['id'], boolean>;
  setDisplayDrawer: (stateFn: (value: boolean) => boolean) => void;
  setTimezoneDrawer: (value: boolean) => void;
}) => {
  const { messages } = localizer;

  return (
    <Box style={styles.toolbar}>
      <ButtonGroup
        variant="outlined"
        aria-label="outlined button group"
        style={{ ...styles.surrounding, ...styles.buttonGroup }}
      >
        <Button
          onClick={() => onNavigate(navigate.TODAY)}
          style={styles.buttonGroup}
        >
          {messages.today}
        </Button>
        <Button
          onClick={() => onNavigate(navigate.PREVIOUS)}
          style={styles.buttonGroup}
        >
          <KeyboardArrowLeftIcon fontSize="small" />
        </Button>
        <Button
          onClick={() => onNavigate(navigate.NEXT)}
          style={styles.buttonGroup}
        >
          <KeyboardArrowRightIcon fontSize="small" />
        </Button>
      </ButtonGroup>

      <Typography style={styles.label}>{label.toUpperCase()}</Typography>

      <ButtonGroup
        variant="outlined"
        aria-label="outlined button group"
        style={{ ...styles.buttonGroup, ...styles.surrounding }}
      >
        {views.map((name: Views[keyof Views]) => (
          <Button
            key={name}
            onClick={() => onView(name)}
            style={{
              ...styles.buttonGroup,
              ...(view === name ? styles.active : {}),
            }}
          >
            {messages[name]}
          </Button>
        ))}
      </ButtonGroup>
      <IconButton
        onClick={() => setTimezoneDrawer(true)}
        style={styles.timezoneSelect}
      >
        <LanguageIcon />
      </IconButton>
      {displaySelector ? (
        <>
          <IconButton onClick={() => setDisplayDrawer((prev) => !prev)}>
            <Badge
              badgeContent={
                Object.keys(staffUsers).filter(
                  (userId) => displayedUsers[userId],
                ).length
              }
              color="primary"
            >
              <List />
            </Badge>
          </IconButton>
        </>
      ) : null}
    </Box>
  );
};

export default memo(Toolbar);
