import { useCallback, useMemo, useState } from 'react';
import { useNotify } from 'react-admin';
import { useTranslate } from 'react-admin';
import {
  Control,
  useForm,
  UseFormSetValue,
  useFormState,
  useWatch,
} from 'react-hook-form';

import {
  useCreateHandover,
  useCreateHandoverRevive,
  useDeleteHandover,
  useUpdateHandover,
} from '@hooks/handover';
import { ArrowUpward, Delete } from '@mui/icons-material';
import CloseIcon from '@mui/icons-material/Close';
import { Box, IconButton, Typography } from '@mui/material';

import { HandoverGeneration, useHandoverGeneration } from './handover.hook';
import {
  logAcceptHandoverGeneration,
  logCreateHandover,
  logEditHandover,
} from '../../analytics/events';
import { DiscussionContext } from '../../common';
import { useDiscussionDetail } from '../../hooks/discussion';
import { useDiscussionContext } from '../../hooks/useDiscussionContext';
import { usePrompt } from '../../hooks/usePrompt';
import { Handover } from '../../types';
import { HandoverReviveCreate, HandoverUpdate } from '../../types/handover';
import { ConfirmDialog } from '../ConfirmDialog/ConfirmDialog';
import { DialogButton } from '../DialogButton/DialogButton';
import { ReviveDialog } from '../discussions/reviveDialog';
import { TextInput } from '../forms/textInput';
import { Button } from '../generic/Button';

const HandoverFormController = ({
  control,
  title,
  onClose,
  handoverGeneration,
  setValue,
  subjectId,
}: {
  control: Control<HandoverUpdate, any>;
  title: string;
  onClose: () => void;
  handoverGeneration?: HandoverGeneration;
  setValue: UseFormSetValue<HandoverUpdate>;
  subjectId: number;
}) => {
  const translate = useTranslate();
  const discussionContext = useDiscussionContext();
  const [displayProblemSuggestion, setDisplayProblemSuggestion] =
    useState(true);
  const [displayObservationsSuggestion, setDisplayObservationsSuggestion] =
    useState(true);
  const [displayAdvicesSuggestion, setDisplayAdvicesSuggestion] =
    useState(true);

  const formState = useFormState({ control });
  const [problem, observations, advices] = useWatch({
    control,
    name: ['problem', 'observations', 'advices'],
  });
  const { isDirty } = formState;

  usePrompt(translate('common.usePrompt'), isDirty);

  const [open, setOpen] = useState(false);
  const protectedOnClose = () => {
    if (isDirty) {
      setOpen(true);
      return;
    }
    onClose();
  };

  const acceptAll = () => {
    if (!handoverGeneration) {
      return;
    }
    handoverGeneration.observations &&
      displayObservationsSuggestion &&
      setValue(
        'observations',
        ((observations ?? '') + ' ' + handoverGeneration.observations).trim(),
      );
    if (discussionContext === DiscussionContext.NURSE) {
      handoverGeneration.problem &&
        displayProblemSuggestion &&
        setValue(
          'problem',
          ((problem ?? '') + ' ' + handoverGeneration.problem).trim(),
        );
      handoverGeneration.advices &&
        displayAdvicesSuggestion &&
        setValue(
          'advices',
          ((advices ?? '') + ' ' + handoverGeneration.advices).trim(),
        );
    }

    setDisplayAdvicesSuggestion(false);
    setDisplayObservationsSuggestion(false);
    setDisplayProblemSuggestion(false);

    logAcceptHandoverGeneration(subjectId);
  };

  return (
    <>
      <ConfirmDialog
        title={translate('common.confirmClose')}
        text={translate('common.confirmCloseText')}
        onClick={onClose}
        onClose={() => setOpen(false)}
        open={open}
      />
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <Typography variant="h6">{title}</Typography>
        <CloseIcon onClick={protectedOnClose} />
      </Box>
      <Box
        sx={{
          flex: 2,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'stretch',
        }}
      >
        {discussionContext === DiscussionContext.NURSE && (
          <>
            <TextInput
              control={control}
              name={'problem'}
              label={translate('handover.problem')}
              rules={{ required: false }}
              sx={{ minWidth: 0 }}
              multiline
              autoFocus
            />
            {handoverGeneration?.problem && displayProblemSuggestion && (
              <Box
                sx={{
                  backgroundColor: 'primary.main10',
                  borderRadius: '0.25rem',
                  padding: '0.25rem',
                }}
              >
                <Typography variant="body2" sx={{ fontSize: '0.75rem' }}>
                  {translate('handover.AISuggestion')}
                </Typography>
                <Typography variant="body1" sx={{ fontSize: '1rem' }}>
                  {handoverGeneration.problem}
                  <IconButton
                    size="small"
                    onClick={() => {
                      setValue(
                        'problem',
                        (
                          (problem ?? '') +
                          ' ' +
                          handoverGeneration.problem
                        ).trim(),
                      );
                      setDisplayProblemSuggestion(false);
                      logAcceptHandoverGeneration(subjectId, 'problem');
                    }}
                  >
                    <ArrowUpward sx={{ fontSize: '1rem' }} />
                  </IconButton>
                </Typography>
              </Box>
            )}
          </>
        )}

        <TextInput
          control={control}
          name={'observations'}
          label={translate('handover.observationsLong')}
          rules={{ required: false }}
          sx={{ minWidth: 0 }}
          multiline
        />
        {handoverGeneration?.observations && displayObservationsSuggestion && (
          <Box
            sx={{
              backgroundColor: 'primary.main10',
              borderRadius: '0.25rem',
              padding: '0.25rem',
            }}
          >
            <Typography variant="body2" sx={{ fontSize: '0.75rem' }}>
              {translate('handover.AISuggestion')}
            </Typography>
            <Typography variant="body1" sx={{ fontSize: '1rem' }}>
              {handoverGeneration.observations}
              <IconButton
                size="small"
                onClick={() => {
                  setValue(
                    'observations',
                    (
                      (observations ?? '') +
                      ' ' +
                      handoverGeneration.observations
                    ).trim(),
                  );
                  setDisplayObservationsSuggestion(false);
                  logAcceptHandoverGeneration(subjectId, 'observations');
                }}
              >
                <ArrowUpward sx={{ fontSize: '1rem' }} />
              </IconButton>
            </Typography>
          </Box>
        )}

        {discussionContext === DiscussionContext.NURSE && (
          <>
            <TextInput
              control={control}
              name={'advices'}
              label={translate('handover.adviceLong')}
              rules={{ required: false }}
              sx={{ minWidth: 0 }}
              multiline
            />
            {handoverGeneration?.advices && displayAdvicesSuggestion && (
              <Box
                sx={{
                  backgroundColor: 'primary.main10',
                  borderRadius: '0.25rem',
                  padding: '0.25rem',
                }}
              >
                <Typography variant="body2" sx={{ fontSize: '0.75rem' }}>
                  {translate('handover.AISuggestion')}
                </Typography>
                <Typography variant="body1" sx={{ fontSize: '1rem' }}>
                  {handoverGeneration.advices}
                  <IconButton
                    size="small"
                    onClick={() => {
                      setValue(
                        'advices',
                        (
                          (advices ?? '') +
                          ' ' +
                          handoverGeneration.advices
                        ).trim(),
                      );
                      setDisplayAdvicesSuggestion(false);
                      logAcceptHandoverGeneration(subjectId, 'advices');
                    }}
                  >
                    <ArrowUpward sx={{ fontSize: '1rem' }} />
                  </IconButton>
                </Typography>
              </Box>
            )}
          </>
        )}

        {/* We have a generation available with at least one field, at least one of them is still displayed  */}
        {/* Plus, this only make sense on the nurse side */}
        {handoverGeneration &&
          discussionContext === DiscussionContext.NURSE &&
          (handoverGeneration.problem ||
            handoverGeneration.advices ||
            handoverGeneration.observations) &&
          (displayAdvicesSuggestion ||
            displayObservationsSuggestion ||
            displayProblemSuggestion) && (
            <Button sx={{ mt: 2 }} onClick={acceptAll}>
              {translate('handover.acceptAllGeneration')}
            </Button>
          )}
      </Box>
    </>
  );
};

export const HandoverCreateForm = ({
  subjectId,
  onClose,
  activeRevives,
}: {
  subjectId: number;
  onClose: () => void;
  activeRevives: number;
}) => {
  const discussion = useDiscussionDetail();
  const notify = useNotify();
  const translate = useTranslate();

  const { handleSubmit, control, formState, setValue } =
    useForm<HandoverUpdate>({
      defaultValues: {
        advices: '',
        observations: '',
        problem: '',
      },
    });
  const { isSubmitting } = formState;

  const { handoverGeneration } = useHandoverGeneration(subjectId);

  const { mutateAsync: createHandoverAsync } = useCreateHandover();
  const [openReviveDialog, setOpenReviveDialog] = useState(false);

  const { mutateAsync: createRevive } = useCreateHandoverRevive();

  const onSubmit = useMemo(
    () =>
      handleSubmit(async (_data: HandoverUpdate) => {
        try {
          const result = await createHandoverAsync({
            ..._data,
            subjectId,
          });
          discussion.createHandover({ handover: result, subjectId });
          logCreateHandover(subjectId, false);
          notify('generic.createSuccess', { type: 'success' });
          onClose();
        } catch (error: any) {
          notify('common.error.withArgs', {
            type: 'error',
            messageArgs: { error: error.message },
          });
        }
      }),
    [createHandoverAsync, discussion, handleSubmit, notify, onClose, subjectId],
  );

  const handleSubmitWithRevive = useCallback(
    async (values: Omit<HandoverReviveCreate, 'handoverId'>) => {
      let handover: Handover;
      const _createHandover = handleSubmit(async (_data: HandoverUpdate) => {
        handover = await createHandoverAsync({
          ..._data,
          subjectId,
        });
      });
      try {
        await _createHandover();
        logCreateHandover(subjectId, true);
        if (handover) {
          try {
            await createRevive({
              ...values,
              handoverId: Number(handover.id),
            });
          } catch (err: any) {
            notify('handover.error', {
              type: 'error',
              messageArgs: { error: err.message },
            });
          }
        }
        onClose();
      } catch (error: any) {
        notify('common.error.withArgs', {
          type: 'error',
          messageArgs: { error: error.message },
        });
      }
    },
    [
      createHandoverAsync,
      createRevive,
      handleSubmit,
      notify,
      onClose,
      subjectId,
    ],
  );

  return (
    <Box sx={{ height: '100%', width: '100%' }}>
      <Box
        sx={{
          height: '100%',
          width: '100%',
          display: 'flex',
          flex: 1,
          overflow: 'auto',
          flexDirection: 'column',
          p: 2,
        }}
      >
        <HandoverFormController
          control={control}
          onClose={onClose}
          title={translate('handover.create')}
          handoverGeneration={handoverGeneration}
          setValue={setValue}
          subjectId={subjectId}
        />
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            flex: 0,
            justifyContent: 'flex-end',
            alignItems: 'stretch',
          }}
        >
          <Button
            sx={{ margin: '0.5rem 0' }}
            variant="contained"
            color="primary"
            loading={isSubmitting}
            value={translate('common.create')}
            fullWidth
            onClick={onSubmit}
          />
          <Button
            sx={{ margin: '0.25rem 0', display: 'block' }}
            variant="outlined"
            color="primary"
            loading={isSubmitting}
            value={`${translate('handover.createWithRevive')}${
              activeRevives > 0 ? ` (${activeRevives})` : ''
            }`}
            fullWidth
            onClick={() => setOpenReviveDialog(true)}
          />
          {activeRevives > 0 && (
            <Typography
              variant="caption"
              color="error"
              sx={{ textAlign: 'center' }}
            >
              {translate('common.already')} {activeRevives}{' '}
              {translate('handover.activeRevives')}
            </Typography>
          )}
          <ReviveDialog
            open={openReviveDialog}
            onClose={() => setOpenReviveDialog(false)}
            onSubmit={handleSubmitWithRevive}
          />
        </Box>
      </Box>
    </Box>
  );
};

export const HandoverEditForm = ({
  handover,
  onClose,
  subjectId,
}: {
  handover: Handover;
  onClose: () => void;
  subjectId: number;
}) => {
  const discussion = useDiscussionDetail();

  const notify = useNotify();
  const translate = useTranslate();

  const { handoverGeneration } = useHandoverGeneration(subjectId);

  const { handleSubmit, control, formState, setValue } =
    useForm<HandoverUpdate>({
      defaultValues: {
        advices: handover?.advices ?? '',
        observations: handover?.observations ?? '',
        problem: handover?.problem ?? '',
      },
    });
  const { isSubmitting } = formState;

  const { mutateAsync: editHandover } = useUpdateHandover({
    handover,
  });

  const { isPending: isLoadingDelete, mutate: deleteHandover } =
    useDeleteHandover({
      handover,
    });

  const onSubmit = useMemo(
    () =>
      handleSubmit(async (handoverUpdate: HandoverUpdate) => {
        try {
          const result = await editHandover({
            ...handoverUpdate,
          });
          discussion.updateHandover(result);
          onClose();
          logEditHandover(handover.subject?.id);
          notify('generic.updateSuccess', { type: 'success' });
        } catch (error: any) {
          notify('common.error.withArgs', {
            type: 'error',
            messageArgs: { error: error.message },
          });
        }
      }),
    [
      editHandover,
      discussion,
      handleSubmit,
      notify,
      onClose,
      handover.subject?.id,
    ],
  );

  return (
    <Box sx={{ height: '100%', width: '100%' }}>
      <Box
        sx={{
          height: '100%',
          width: '100%',
          display: 'flex',
          flex: 1,
          overflow: 'auto',
          flexDirection: 'column',
          p: 2,
        }}
      >
        <HandoverFormController
          control={control}
          onClose={onClose}
          title={translate('handover.edit')}
          handoverGeneration={handoverGeneration}
          setValue={setValue}
          subjectId={subjectId}
        />

        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            flex: 0,
            justifyContent: 'flex-end',
          }}
        >
          <Button
            sx={{ flex: 1, margin: '1rem 0' }}
            variant="contained"
            color="primary"
            loading={isSubmitting}
            value={translate('common.update')}
            fullWidth
            onClick={onSubmit}
            disabled={isSubmitting || isLoadingDelete}
          />
          <DialogButton
            size="small"
            title={translate('handover.deleteConfirmTitle')}
            text={translate('handover.deleteConfirmContent')}
            cancelText={translate('handover.cancel')}
            confirmText={translate('handover.confirm')}
            onClick={() =>
              deleteHandover(handover, {
                onSuccess: onClose,
                onError: (error) => {
                  notify('common.error.withArgs', {
                    type: 'error',
                    messageArgs: {
                      error: error.message,
                    },
                  });
                },
              })
            }
            //@ts-ignore
            component={Button}
            loading={isLoadingDelete}
            disabled={isSubmitting || isLoadingDelete}
            value={translate('common.delete')}
            variant="text"
            startIcon={<Delete />}
            color="error"
            sx={{
              marginTop: '1rem',
              marginBottom: '1rem',
              marginLeft: '2px',
              flex: 1,
            }}
          />
        </Box>
      </Box>
    </Box>
  );
};
