import { useTranslate } from 'react-admin';

import { Roles } from '@boTypes/user';
import { Category, Videocam } from '@mui/icons-material';
import {
  Card,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Typography,
} from '@mui/material';

import { useSelector } from '../../store';

export const CategoryCard = () => {
  const translate = useTranslate();
  const roles = useSelector((state) => state.user.roles);

  if (!roles.includes(Roles.ADMIN)) {
    return null;
  }

  return (
    <Card sx={{ padding: 2 }}>
      <Typography variant="subtitle1" color="primary" sx={{ paddingBottom: 1 }}>
        {translate('admin.categories.title')}
      </Typography>
      <List dense>
        <ListItem disablePadding>
          <ListItemButton disableGutters component="a" href="/#/categories">
            <ListItemIcon sx={{ minWidth: '2rem' }}>
              <Category fontSize="small" />
            </ListItemIcon>
            <ListItemText primary={translate('admin.categories.questions')} />
          </ListItemButton>
        </ListItem>
        <ListItem disablePadding>
          <ListItemButton
            disableGutters
            component="a"
            href="/#/consultation-categories"
          >
            <ListItemIcon sx={{ minWidth: '2rem' }}>
              <Videocam fontSize="small" />
            </ListItemIcon>
            <ListItemText
              primary={translate('admin.categories.consultations')}
            />
          </ListItemButton>
        </ListItem>
      </List>
    </Card>
  );
};
