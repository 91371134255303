import CheckIcon from '@mui/icons-material/Check';
import { Box, Button, Typography } from '@mui/material';

import { PlanningAvailValue } from '../../../types/planning';

const ICON_SIZE = 24;

const Yes = () => (
  <CheckIcon
    sx={{ color: 'primary.main50', width: ICON_SIZE, height: ICON_SIZE }}
  />
);

const IDontKnow = () => (
  <Typography
    sx={{
      color: 'primary.main10',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      width: ICON_SIZE,
      height: ICON_SIZE,
    }}
    noWrap
  >
    ?
  </Typography>
);

export const CheckButton = ({
  value,
  onPress,
  yesCount = 0,
}: {
  value?: number;
  onPress: (value: PlanningAvailValue) => void;
  yesCount?: number;
}) => {
  return (
    <Box
      sx={{
        height: '100%',
        p: 0,
        m: 0,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <Box sx={{ display: 'inline-flex', alignItems: 'center' }}>
        <Button
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            p: 0,
            m: 0,
            minWidth: 0,
          }}
          onClick={() =>
            onPress(
              value === PlanningAvailValue.YES
                ? PlanningAvailValue.NO
                : PlanningAvailValue.YES,
            )
          }
        >
          {value === PlanningAvailValue.YES ? <Yes /> : <IDontKnow />}
        </Button>
        <Typography
          sx={{
            paddingLeft: 0.25,
            fontSize: '0.6rem',
            color: 'background.grey',
          }}
        >
          {`(${yesCount})`}
        </Typography>
      </Box>
    </Box>
  );
};
