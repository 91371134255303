import chunk from 'lodash/chunk';
import groupBy from 'lodash/groupBy';
import { useState } from 'react';
import { useTranslate } from 'react-admin';
import { ViewStyle } from 'react-native';
import { useNavigate } from 'react-router';

import { FormSettingsUpdate } from '@hooks/form-builder';
import useWindowDimensions from '@hooks/useWindowDimensions';
import { Settings } from '@mui/icons-material';
import { Box, IconButton, Typography } from '@mui/material';
import { Form } from '@teammay/form-core';

import { CARD_MAX_WIDTH, FormCard } from './FormCard';
import { FormSettingsModal } from './FormSettingsForm';
import { colorTokens } from '../../../themes';

const styles = {
  card: {
    borderRadius: 8,
    overflow: 'hidden',
    borderWidth: 1,
    borderStyle: 'solid',
    borderColor: colorTokens.content.subtleBackground,
  } satisfies ViewStyle,
};
export const FormBuilderListRow = ({
  forms,
}: {
  forms: FormSettingsUpdate[];
}) => {
  const { width } = useWindowDimensions();
  const cardPerLine = Math.floor(width / (CARD_MAX_WIDTH + 16));
  const [editedForm, setEditedForm] = useState<FormSettingsUpdate | null>(null);
  const navigate = useNavigate();

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
      {chunk(forms, cardPerLine).map((formRow, idx) => (
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            gap: 1,
          }}
          key={idx}
        >
          {formRow.map((form) => (
            <Box key={form.id} sx={{ position: 'relative' }}>
              <FormCard
                style={styles.card}
                key={form.id}
                {...form}
                onPress={() => navigate(`/forms/${form.id}`)}
              />
              <IconButton
                sx={{ position: 'absolute', top: 4, right: 4, zIndex: 1 }}
                aria-label="Edit"
                size="small"
                onClick={() => setEditedForm(form)}
              >
                <Settings />
              </IconButton>
            </Box>
          ))}
        </Box>
      ))}
      <FormSettingsModal
        form={editedForm}
        onClose={() => setEditedForm(null)}
      />
    </Box>
  );
};

export const statusMapping = (status: Form['status']) => {
  switch (status) {
    case 'draft':
      return 'forms.status.draft';
    case 'published':
      return 'forms.status.published';
    case 'archived':
      return 'forms.status.archived';
  }
};

export const FormBuilderList = ({ forms }: { forms: FormSettingsUpdate[] }) => {
  const rows = groupBy(forms, 'status') as Record<Form['status'], Form[]>;
  const translate = useTranslate();

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 4 }}>
      {Object.keys(rows)
        .sort()
        .toReversed()
        .map((status) => (
          <Box key={status}>
            <Typography variant="h6" sx={{ mb: 1, fontWeight: 'bold' }}>
              {translate(statusMapping(status as Form['status']))}
            </Typography>
            <FormBuilderListRow key={status} forms={rows[status]} />
          </Box>
        ))}
    </Box>
  );
};
