import { intersection } from 'lodash';
import { useMemo, useState } from 'react';
import { usePermissions, useTranslate } from 'react-admin';

import { SubjectExplorer, SubjectExplorerPopulated } from '@boTypes/subject';
import { Roles } from '@boTypes/user';
import { useDiscussionFamilies } from '@hooks/family';
import {
  Box,
  CircularProgress,
  Grid,
  Theme,
  Typography,
  useMediaQuery,
} from '@mui/material';

import { useSimilarQuestions } from './hooks';
import { logSimilarQuestionSubjectSelected } from '../../analytics/events';
import emptyDiscussion from '../../assets/empty_discussion.webp';
import { SubjectExplorerDetail } from '../../pages/subjectExplorer/subjectExplorer.detail';
import { SubjectExplorerCard } from '../../pages/subjectExplorer/subjectExplorerCard';

export const SimilarQuestion = ({
  currentSubjectId,
}: {
  currentSubjectId: number;
}) => {
  const { permissions } = usePermissions();
  const translate = useTranslate();
  const bigScreen = useMediaQuery<Theme>((theme) => theme.breakpoints.up('md'));
  const [subjectId, setSubjectId] = useState<number>();

  // make call to similar question
  const { subjects, isLoading } = useSimilarQuestions(currentSubjectId);

  const { data: families } = useDiscussionFamilies(
    subjects?.map((subject) => subject.discussion),
  );
  const subjectsAndFamily: SubjectExplorerPopulated[] = useMemo(
    () =>
      subjects?.map((subject, index) => ({ subject, family: families[index] })),
    [subjects, families],
  );

  if (
    intersection(permissions, [
      Roles.ADMIN,
      Roles.STAFF_MANAGER,
      Roles.SUPERUSER,
      Roles.HEALTH_PRO,
    ]).length === 0
  ) {
    return null;
  }

  return (
    <Grid
      container
      sx={{
        height: '100%',
      }}
    >
      {Boolean(bigScreen || !subjectId) && (
        <Grid
          item
          sx={(theme) => ({
            flexDirection: 'column',
            height: '100%',
            flex: 2,
            borderRightWidth: '1px',
            borderRightStyle: 'solid',
            borderRightColor: 'primary.main10',
            alignItems: 'stretch',
            display: 'flex',
            width: 'calc(100vw - 50px)',
            [theme.breakpoints.down('sm')]: {
              maxWidth: '100vw',
            },
          })}
          xs={12}
          md={3}
        >
          <Box sx={{ height: '100%', width: '100%' }}>
            {isLoading ? (
              <CircularProgress size={24} sx={{ p: 3 }} />
            ) : subjectsAndFamily?.length ? (
              subjectsAndFamily?.map(({ family, subject }) => (
                <SubjectExplorerCard
                  key={subject.id}
                  subject={subject}
                  family={family}
                  selected={subjectId === subject.id}
                  onCardClick={(s: SubjectExplorer) => {
                    logSimilarQuestionSubjectSelected(s.id, currentSubjectId);
                    setSubjectId(s.id);
                  }}
                />
              ))
            ) : (
              <>
                <Typography variant="body1" sx={{ p: 1, paddingTop: 2 }}>
                  {translate('similarQuestion.noMatch')}
                </Typography>
                <Typography variant="body2" sx={{ p: 1 }}>
                  {translate('similarQuestion.noMatchNB')}
                </Typography>
              </>
            )}
          </Box>
        </Grid>
      )}
      <Grid
        item
        sx={(theme) => ({
          flexDirection: 'column',
          height: '100%',
          flex: 8,
          [theme.breakpoints.down('md')]: {
            display: subjectId ? 'flex' : 'none',
          },
          [theme.breakpoints.up('md')]: {
            display: 'flex',
          },
        })}
        xs={12}
        md={9}
      >
        {subjectId ? (
          <SubjectExplorerDetail
            subjectId={subjectId}
            setSubjectId={setSubjectId}
          />
        ) : (
          <Box
            sx={{
              height: '100%',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <img
              src={emptyDiscussion}
              alt="empty selection"
              style={{
                maxHeight: '300px',
                marginTop: '-6rem',
                marginBottom: '1rem',
              }}
            />
            <Typography variant="body2">
              {translate('subject.selectOne')}
            </Typography>
          </Box>
        )}
      </Grid>
    </Grid>
  );
};
