import 'dayjs/locale/fr';
import { ComponentProps, useCallback, useMemo } from 'react';
import { StyleSheet } from 'react-native';
import { useNavigate } from 'react-router';

import { MayContextMenu } from '@components/subjects/discussionChat';
import { getIdsInfoFromDiscussion } from '@components/subjects/utils';
import { Grid, Paper } from '@mui/material';

import { useSubject, useSubjectMessages } from './subjectExplorer.hook';
import {
  MessageList,
  ContextMenuType,
} from '../../components/discussions/chat/messageList';
import { SubjectSummary } from '../../components/subject/subjectSummary';
import { ChatHeader } from '../../components/subjects/patientHeader';

const styles = StyleSheet.create({
  grid: {
    display: 'flex',
    height: '100%',
  },
  chatContainer: {
    display: 'flex',
    height: 'calc(100vh - 97px)', // 48px (appbar) + 48px (chat header) + 1px (border)
  },
  infoPanel: {
    height: '100%',
    maxHeight: '100%',
  },
});

export const SubjectExplorerDetail = ({
  subjectId,
  setSubjectId,
}: {
  subjectId: number;
  setSubjectId?: React.Dispatch<React.SetStateAction<number | undefined>>;
}) => {
  const navigate = useNavigate();
  const { data: subject } = useSubject(subjectId);
  const { data } = useSubjectMessages(subject);
  const populatedDiscussion = useMemo(
    () =>
      subject
        ? { ...subject.discussion, appUsers: subject.appUsers }
        : undefined,
    [subject],
  );

  const ChatContextMenu = useCallback(
    (contextProps: ComponentProps<ContextMenuType>) => {
      return (
        <MayContextMenu
          readonly={true}
          {...contextProps}
          discussionId={subject?.discussion?.id}
        />
      );
    },
    [subject?.discussion?.id],
  );

  if (!subjectId) {
    return null;
  }

  const { appUserId, familyId, childId } =
    // @ts-ignore
    getIdsInfoFromDiscussion(populatedDiscussion);

  return (
    <>
      <ChatHeader
        appUserId={appUserId}
        familyId={familyId}
        childId={childId}
        extraInfo={false}
        backAction={() =>
          setSubjectId
            ? setSubjectId(undefined)
            : navigate('/subjects-explorer')
        }
      />
      <Grid container spacing={0} style={styles.grid}>
        <Grid item xs={12} sm={7}>
          <Paper elevation={0} style={styles.chatContainer}>
            {data && !!data.messages.length && (
              <MessageList
                messages={data.messages}
                ContextMenu={ChatContextMenu}
                participants={data.participants}
              />
            )}
          </Paper>
        </Grid>
        <Grid
          item
          xs={12}
          sm={5}
          zeroMinWidth
          sx={{
            borderLeftWidth: '1px',
            borderLeftStyle: 'solid',
            borderLeftColor: 'primary.main10',
          }}
        >
          <Paper elevation={0} style={styles.infoPanel}>
            <SubjectSummary subject={subject} />
          </Paper>
        </Grid>
      </Grid>
    </>
  );
};
