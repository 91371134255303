import { ChipField, useRecordContext, useTranslate } from 'react-admin';

import { TO_DO, CLOSED, CANCELED } from '../../common/constants';
import { Consultation } from '../../types';

const styles = {
  todo: { backgroundColor: 'primary.subtle' },
  closed: { backgroundColor: 'primary.main', color: 'white' },
  canceled: { backgroundColor: 'error.main' },
};

interface StatusChipFieldProps {
  label?: string;
}

export const consultationStatusStylesMapping = {
  [TO_DO]: styles.todo,
  [CLOSED]: styles.closed,
  [CANCELED]: styles.canceled,
};

export const StatusChipField = ({}: StatusChipFieldProps) => {
  const record = useRecordContext<Consultation>();
  const translate = useTranslate();

  return (
    <ChipField
      sx={consultationStatusStylesMapping[record.status]}
      label={translate('common.status')}
      source="status"
    />
  );
};
