import { useTranslate } from 'react-admin';

import { EntitySlotType } from '@boTypes/slot';
import { StaffUser } from '@boTypes/staffUser';
import { titleToPlanningJob } from '@boTypes/user';
import { DialogButton } from '@components/DialogButton/DialogButton';
import { Avatar } from '@components/fields/AvatarField';
import { Close as CloseIcon, Delete, Edit } from '@mui/icons-material';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import { Box, IconButton, Popover, Typography } from '@mui/material';

import { CalendarEvent } from '.';
import { useSelector } from '../../store';
import { colorTokens } from '../../themes';
import { stringToColor } from '../../utils/color';
import { getReadableDate, getReadableHourRange } from '../../utils/date';

const PopoverActions = ({
  displayActions,
  callbackActions,
  selectedEvent,
  onClosePopover,
}: {
  displayActions?: {
    update?: (slot: CalendarEvent) => boolean;
    delete?: (slot: CalendarEvent) => boolean;
  };
  callbackActions?: {
    update?: (slot: CalendarEvent) => void;
    remove?: (slot: CalendarEvent) => void;
  };
  selectedEvent: CalendarEvent;
  onClosePopover: () => void;
}) => {
  const translate = useTranslate();

  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'space-between',
        alignContent: 'center',
        alignItems: 'center',
        marginBottom: '1rem',
      }}
    >
      <Typography sx={{ fontWeight: 'bold', fontSize: '1.25rem' }}>
        {selectedEvent.entityType === EntitySlotType.additionalHour
          ? translate('planning.event.additionalHour')
          : translate('planning.event.slot')}
      </Typography>

      <Box sx={{ display: 'flex', flexDirection: 'row' }}>
        <Box
          sx={
            (displayActions?.update?.(selectedEvent) &&
              callbackActions?.update) ||
            (displayActions?.delete?.(selectedEvent) && callbackActions?.remove)
              ? {
                  paddingRight: '0.5rem',
                  marginRight: '0.5rem',
                  borderRightWidth: 1,
                  borderRightStyle: 'solid',
                  borderRightColor: 'primary.main10',
                }
              : {}
          }
        >
          {displayActions?.update?.(selectedEvent) &&
          callbackActions?.update ? (
            <IconButton
              aria-label="edit"
              sx={{
                color: (theme) => theme.palette.grey[500],
              }}
              onClick={() => {
                // @ts-ignore
                callbackActions.update?.(selectedEvent);
                onClosePopover();
              }}
              size="small"
            >
              <Edit />
            </IconButton>
          ) : null}
          {displayActions?.delete?.(selectedEvent) &&
          callbackActions?.remove ? (
            <IconButton
              aria-label="remove"
              sx={{
                color: (theme) => theme.palette.grey[500],
              }}
              onClick={() => {
                // @ts-ignore
                callbackActions.remove?.(selectedEvent);
                onClosePopover();
              }}
              size="small"
            >
              <Delete />
            </IconButton>
          ) : null}
        </Box>
        <IconButton
          aria-label="close"
          sx={{
            color: (theme) => theme.palette.grey[500],
          }}
          onClick={onClosePopover}
          size="small"
        >
          <CloseIcon />
        </IconButton>
      </Box>
    </Box>
  );
};

const ExtraActions = ({
  slot,
  actions,
  onClosePopover,
  isAdmin = false,
}: {
  slot: CalendarEvent;
  actions: {
    addAttribution?: (slotId: CalendarEvent['id']) => void;
    removeAttribution: (slotId: CalendarEvent['id']) => void;
  };
  onClosePopover: () => void;
  isAdmin?: boolean;
}) => {
  const { userId, jobTitle } = useSelector((state) => state.user);
  const translate = useTranslate();
  if (!slot.entityType) {
    return null;
  }

  if (slot.entityType === EntitySlotType.additionalHour) {
    return null;
  }
  if (!isAdmin && slot.staffUserId && slot.staffUserId !== userId) {
    return null;
  }

  if (!isAdmin && slot.job !== titleToPlanningJob[jobTitle]) {
    return null;
  }

  return (
    <Box
      sx={{
        display: 'flex',
        direction: 'row',
        marginBottom: '0.5rem',
        marginTop: '2rem',
      }}
    >
      {slot.staffUserId ? (
        <DialogButton
          title={translate('planning.event.attribution')}
          text={translate('planning.event.cancelAttributionConfirmText')}
          onClick={() => {
            actions.removeAttribution(slot.id);
            onClosePopover();
          }}
          color={'error'}
          variant="outlined"
        >
          {translate('planning.event.cancelAttribution')}
        </DialogButton>
      ) : (
        actions.addAttribution &&
        slot.start >= new Date() && (
          <DialogButton
            title={translate('planning.event.attribution')}
            text={translate('planning.event.getAttributionConfirmText')}
            onClick={() => {
              actions.addAttribution(slot.id);
              onClosePopover();
            }}
            variant="outlined"
          >
            {translate('planning.event.getAttribution')}
          </DialogButton>
        )
      )}
    </Box>
  );
};

export const CalendarPopover = ({
  selectedEvent,
  showPopover,
  anchorEl,
  onClosePopover,
  tz,
  staffUsers,
  displayActions,
  callbackActions,
  displayExtraActions = false,
  callbackExtraActions,
  displayOtherAvailable = false,
  isAdmin = false,
}: {
  selectedEvent: CalendarEvent;
  showPopover: boolean;
  anchorEl: Element | null;
  onClosePopover: () => void;
  tz: string;
  staffUsers: Record<StaffUser['id'], StaffUser>;
  displayActions?: {
    update?: (slot: CalendarEvent) => boolean;
    delete?: (slot: CalendarEvent) => boolean;
  };
  callbackActions?: {
    update?: (slot: CalendarEvent) => void;
    remove?: (slot: CalendarEvent) => void;
  };
  displayExtraActions?: boolean;
  callbackExtraActions?: {
    addAttribution?: (slotId: CalendarEvent['id']) => void;
    removeAttribution: (slotId: CalendarEvent['id']) => void;
  };
  displayOtherAvailable?: boolean;
  isAdmin?: boolean;
}) => {
  const translate = useTranslate();
  if (!selectedEvent) {
    return null;
  }

  return (
    <Popover
      open={showPopover}
      anchorEl={anchorEl}
      onClose={onClosePopover}
      sx={{
        boxShadow:
          '0px 5px 5px -3px rgba(0,0,0,0.2), 0px 8px 10px 1px rgba(0,0,0,0.14), 0px 3px 14px 2px rgba(0,0,0,0.12)',
      }}
      anchorOrigin={{
        vertical: 'center',
        horizontal: 'center',
      }}
      PaperProps={{ sx: { p: 2, minWidth: 300 } }}
      transitionDuration={50}
    >
      <PopoverActions
        displayActions={displayActions}
        callbackActions={callbackActions}
        selectedEvent={selectedEvent}
        onClosePopover={onClosePopover}
      />
      <Box
        sx={{
          display: 'flex',
          direction: 'row',
          alignItems: 'center',
          marginBottom: '1rem',
        }}
      >
        <Box
          sx={{
            width: '1.5rem',
            height: '1.5rem',
            borderRadius: '50%',
            backgroundColor:
              selectedEvent.color ||
              (selectedEvent?.staffUserId
                ? stringToColor(
                    staffUsers?.[selectedEvent?.staffUserId]?.fullName,
                  )
                : colorTokens.content.danger),
          }}
        />
        <Box
          sx={{
            display: 'flex',
            width: 'calc(100% - 2rem)',
            alignItems: 'center',
            flexDirection: 'row',
            justifyContent: 'space-between',
            marginLeft: '1rem',
          }}
        >
          <Box>
            <Typography>{selectedEvent?.title}</Typography>
            <Typography sx={{ color: 'primary.main50', fontSize: '0.875rem' }}>
              {getReadableDate(selectedEvent?.start, tz)}
            </Typography>
          </Box>
          {Boolean(selectedEvent?.staffUserId) && (
            <Avatar
              user={staffUsers?.[selectedEvent?.staffUserId]}
              size="custom"
              sx={{ borderWidth: 1, borderColor: 'primary.main10' }}
            />
          )}
        </Box>
      </Box>
      <Box sx={{ display: 'flex', direction: 'row', marginBottom: '0.5rem' }}>
        <AccessTimeIcon sx={{ color: 'primary.main50', marginRight: '1rem' }} />
        <Typography>
          {getReadableHourRange(selectedEvent?.start, selectedEvent?.end, tz)}
        </Typography>
      </Box>
      {displayExtraActions && (
        <ExtraActions
          slot={selectedEvent}
          actions={callbackExtraActions}
          onClosePopover={onClosePopover}
          isAdmin={isAdmin}
        />
      )}
      {displayOtherAvailable &&
        selectedEvent?.entityType !== EntitySlotType.additionalHour && (
          <>
            <Typography variant="body2" sx={{ padding: 1, paddingLeft: 4 }}>
              {(selectedEvent?.otherAvailable?.length || 0) === 0
                ? translate('planning.event.noOtherAvailable')
                : translate('planning.event.otherAvailable', {
                    smart_count: selectedEvent?.otherAvailable?.length,
                  })}
            </Typography>
            {selectedEvent?.otherAvailable?.map((userName, ndx) => (
              <Box
                sx={{ paddingLeft: 4, display: 'flex', alignItems: 'center' }}
                key={ndx}
              >
                <Box
                  component={'span'}
                  sx={{
                    width: 16,
                    height: 16,
                    borderRadius: '50%',
                    backgroundColor: stringToColor(userName),
                    display: 'inline-block',
                    marginRight: 3,
                  }}
                />
                <Typography variant="body2" component={'span'}>
                  {userName}
                </Typography>
              </Box>
            ))}
          </>
        )}
    </Popover>
  );
};
