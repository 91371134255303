import { capitalize } from 'lodash';
import { StyleSheet, Text, View } from 'react-native';

import { formatTZ } from '@utils/date';

import { TILE_WIDTH, GAP, DAY_LABEL_HEIGHT } from './constants';
import Tile from './Tile';
import { colorTokens } from '../../../../themes';
import { DayData } from '../types';

const styles = StyleSheet.create({
  label: {
    height: DAY_LABEL_HEIGHT,
    textAlign: 'center',
    color: colorTokens.content.primary,
    fontSize: 12,
  },
  today: {
    color: colorTokens.content.secondary,
    fontWeight: 'bold',
  },
});

interface DayProps {
  label: string;
  data: DayData | undefined;
}

const Day = ({ label, data = {} }: DayProps) => {
  const isToday = capitalize(formatTZ(undefined, 'ddd\nDD')) === label;
  return (
    <View style={{ gap: GAP }}>
      <Text style={{ ...styles.label, ...(isToday ? styles.today : {}) }}>
        {label}
      </Text>
      {Array.from({ length: 24 }).map((_, i) => (
        <Tile key={i} width={TILE_WIDTH} entries={data[i]} />
      ))}
    </View>
  );
};

export default Day;
