import { zip } from 'lodash';
import { useTranslate } from 'react-admin';
import ReactMarkdown from 'react-markdown';

import { FertiTip } from '@boTypes/fertiTip';
import { Avatar, Box, Dialog, DialogContent, Typography } from '@mui/material';

import { DialogTitle } from '../generic/Dialog';

export const WIDTH = 148;
export const HEIGHT = 120;

export const FertiTipItemDialog = ({
  item,
  open,
  close,
}: {
  item: FertiTip;
  open: boolean;
  close: () => void;
}) => {
  const translate = useTranslate();
  const authors = zip(item.authorNames, item.authorImages).map(
    ([name, image]) => (
      <Box
        key={name}
        sx={{
          display: 'flex',
          justifyContent: 'flex-start',
          alignItems: 'center',
        }}
      >
        <Avatar
          src={image}
          alt={name}
          sx={{
            width: 24,
            height: 24,
            borderWidth: 1,
            borderColor: 'background.grey',
            marginRight: 1,
          }}
        />
        <Typography variant="body2">{name}</Typography>
      </Box>
    ),
  );
  return (
    <Dialog
      open={open}
      onClose={close}
      aria-labelledby={item.title}
      aria-describedby={item.title}
      maxWidth="md"
    >
      <DialogTitle onClose={close}>{item.title}</DialogTitle>
      <DialogContent>
        {authors}
        <Typography variant="body2" sx={{ marginTop: 1 }}>
          {translate('fertiTip.cycleNumber', { cycleNumber: item.cycleNumber })}
        </Typography>
        <Typography variant="body2">
          {translate('fertiTip.cyclePosition', {
            cyclePosition: item.cyclePosition,
          })}
        </Typography>
        <ReactMarkdown>{item.content}</ReactMarkdown>
      </DialogContent>
    </Dialog>
  );
};
