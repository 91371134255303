import { useTranslate } from 'react-admin';

import { DialogTitle } from '@components/generic/Dialog';
import { Dialog, DialogContent } from '@mui/material';
import { Media } from '@teammay/mayssenger';

const MediaType = {
  image: 'image' as const,
  video: 'video' as const,
}; // to reflect chat-core MediaType types

export const MediaViewer = ({
  open,
  onClose,
  media,
}: {
  open: boolean;
  onClose: () => void;
  media: Media;
}) => {
  const translate = useTranslate();
  return (
    <Dialog open={open} onClose={onClose} fullScreen fullWidth>
      <DialogTitle onClose={onClose}>{translate('common.media')}</DialogTitle>
      <DialogContent>
        {media?.type === MediaType.image && (
          <img
            style={{ width: '100%', height: '100%', objectFit: 'contain' }}
            src={media.uri}
          />
        )}
        {media?.type === MediaType.video && (
          <video controls>
            <source src={media.uri} />
            <source src={media.uri} type="video/mp4" />
            Your browser does not support this video format.
          </video>
        )}
      </DialogContent>
    </Dialog>
  );
};
