import React from 'react';

import { Close as CloseIcon } from '@mui/icons-material';
import {
  DialogTitle as MuiDialogTitle,
  DialogTitleProps as MuiDialogTitleProps,
  IconButton,
  Box,
} from '@mui/material';

interface DialogTitleProps
  extends Omit<MuiDialogTitleProps, 'children' | 'onClose'> {
  children: React.ReactNode;
  onClose: () => void;
}

export const DialogTitle = ({
  children,
  onClose,
  sx,
  ...props
}: DialogTitleProps) => {
  return (
    <MuiDialogTitle
      sx={{
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignContent: 'center',
        alignItems: 'center',
        fontWeight: '500',
        ...sx,
      }}
      variant="h1"
      color="textPrimary"
      {...props}
    >
      <Box
        sx={{
          marginRight: '2rem',
          display: 'flex',
          flexDirection: 'row',
          gap: 1,
          alignItems: 'center',
        }}
      >
        {children}
      </Box>
      {onClose ? (
        <IconButton
          aria-label="close"
          sx={{
            color: 'primary.main',
          }}
          onClick={onClose}
          size="medium"
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
};
