import classnames from 'classnames';
import { CSSProperties, ComponentProps, ElementType } from 'react';
import { Identifier, useRecordContext } from 'react-admin';

import {
  Avatar as MUIAvatar,
  Typography,
  Tooltip,
  SxProps,
} from '@mui/material';
import { styled } from '@mui/material/styles';

import { useSelector } from '../../store';
import { colorTokens } from '../../themes';
import { User } from '../../types';
import { getFullName } from '../../utils';
import { stringToColor } from '../../utils/color';

type AvatarUser = {
  id?: Identifier;
  firstName: string;
  lastName?: string;
  avatar?: string;
};

interface AvatarFieldProps {
  label?: string;
  source?: string;
  body?: string;
  alt?: string;
  component?: 'div' | 'span';
  name?: string;
  size?: 'small' | 'tiny' | 'custom';
}
interface AvatarProps extends AvatarFieldProps {
  user?: AvatarUser;
  sx?: SxProps;
  style?: CSSProperties;
}

function stringAvatar(user: AvatarUser, sx = {} as SxProps) {
  const name = (user.firstName + ' ' + (user.lastName ?? '')).trim();

  return {
    sx: {
      ...sx,
      bgcolor: stringToColor(name),
    },
    children: user.lastName
      ? `${user.firstName[0]}${user.lastName[0]}`.toUpperCase()
      : `${user.firstName[0]}`.toUpperCase(),
  };
}

const classes = {
  small: 'small',
  tiny: 'tiny',
  meBorder: 'meBorder',
};
const StyledAvatar = styled(MUIAvatar)<
  // due to a bug in styled styling we use this bypass https://github.com/mui/material-ui/issues/29875#issuecomment-1087569925
  ComponentProps<typeof MUIAvatar> & { component: ElementType }
>(({ theme }) => ({
  '&.small': {
    width: theme.spacing(3),
    height: theme.spacing(3),
    // @ts-ignore
    backgroundColor: theme.palette.background.grey,
    border: '1px solid',
    // @ts-ignore
    borderColor: theme.palette.background.grey,
  },
  '&.tiny': {
    width: theme.spacing(2.25),
    height: theme.spacing(2.25),
    // @ts-ignore
    backgroundColor: theme.palette.background.grey,
    border: '1px solid',
    // @ts-ignore
    borderColor: theme.palette.background.grey,
    fontSize: '0.875rem',
  },
  '&.meBorder': {
    border: '1px solid ' + colorTokens.content.primary,
  },
}));

export const Avatar = ({
  user,
  source = 'avatar',
  alt = 'avatar',
  component = 'div',
  name = '',
  size = 'small',
  sx,
  style,
}: AvatarProps) => {
  const fullName = name || getFullName(user);
  const isMe = useSelector((state) => `${state.user.userId}` === `${user?.id}`);

  return (
    <Tooltip title={fullName} aria-label={fullName}>
      {user && user[source] ? (
        <StyledAvatar
          src={user && user[source]}
          component={component}
          className={classnames(
            size === 'custom'
              ? undefined
              : {
                  [classes.small]: size === 'small',
                  [classes.tiny]: size === 'tiny',
                },
            isMe ? classes.meBorder : undefined,
          )}
          alt={alt}
          sx={sx}
          style={style}
        />
      ) : (
        <StyledAvatar
          {...stringAvatar(user, sx)}
          component={component}
          className={classnames(
            size === 'custom'
              ? undefined
              : {
                  [classes.small]: size === 'small',
                  [classes.tiny]: size === 'tiny',
                },
            isMe ? classes.meBorder : undefined,
          )}
          style={style}
          alt={alt}
        />
      )}
    </Tooltip>
  );
};

export const AvatarField = (props: AvatarFieldProps) => {
  const record = useRecordContext<User>();
  if (!record) {
    return null;
  }
  return <Avatar {...props} user={record} />;
};

export const AvatarAndText = ({
  user,
  avatarSx,
  textSx,
}: {
  user: User;
  avatarSx?: SxProps;
  textSx?: SxProps;
}) => {
  if (!user) {
    return null;
  }
  const name = getFullName(user);
  return (
    <span style={{ display: 'inline-flex', alignItems: 'center' }}>
      <Avatar
        component="span"
        user={user}
        alt={name}
        name={name}
        size="tiny"
        sx={avatarSx}
      />
      <Typography
        variant="subtitle2"
        component="span"
        sx={{ marginLeft: '0.25rem', ...textSx }}
      >
        {name}
      </Typography>
    </span>
  );
};

export const AvatarAndTextField = () => {
  const record = useRecordContext<User>();

  return <AvatarAndText user={record} />;
};
