import { colorTokens } from '../../../themes';

export const guideTheme = {
  // This is clearer and darker than our actual color in our theme
  theme1: {
    background: '#DAEFEC',
    color: '#2A9082',
    accent: '#FFFFFF50',
  },
  theme2: {
    background: '#F8F2E9',
    color: '#EF6027',
    accent: '#FFFFFF70',
  },
  theme3: {
    background: '#EAE7F7',
    color: '#8F72C3',
    accent: '#FFFFFF50',
  },
} as const;

export const guideDesignTokens = {
  fonts: {
    h1: {
      fontFamily: 'Lora',
      fontWeight: '600',
      fontSize: 28,
      lineHeight: 28 * 1.1,
    },
    h2: {
      fontFamily: 'Lora',
      fontWeight: '600',
      fontSize: 18,
      lineHeight: 18 * 1.2,
    },
    h3: {
      fontFamily: 'Manrope',
      fontWeight: '600',
      fontSize: 16,
      lineHeight: 16 * 1.4,
    },
    h4: {
      fontFamily: 'Manrope',
      fontWeight: '600',
      fontSize: 16,
      lineHeight: 16 * 1.4,
      color: colorTokens.content.primary,
    },
    body: {
      sm: {
        fontFamily: 'Manrope',
        fontWeight: '400',
        fontSize: 13,
        lineHeight: 20,
      },
      md: {
        fontFamily: 'Manrope',
        color: colorTokens.content.primary,
        fontWeight: '600',
        fontSize: 13,
        lineHeight: 20,
      },
    },
  },
} as const;
