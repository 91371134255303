import classnames from 'classnames';
import React, { useCallback } from 'react';
import { useTranslate } from 'react-admin';

import {
  Avatar,
  Tooltip,
  Typography,
  Dialog,
  DialogContent,
  AvatarProps,
  Box,
} from '@mui/material';
import { styled } from '@mui/material/styles';

import { DialogTitle } from './generic/Dialog';
import { JobTitle, titleToColor } from '../types/user';

const PREFIX = 'AvatarItem';

const classes = {
  defaultBackground: `${PREFIX}-defaultBackground`,
  avatarContainer: `${PREFIX}-avatarContainer`,
  avatar: `${PREFIX}-avatar`,
  modalAvatar: `${PREFIX}-modalAvatar`,
  suggestedAvatar: `${PREFIX}-suggestedAvatar`,
  suggestedDenseAvatar: `${PREFIX}-suggestedDenseAvatar`,
  denseAvatar: `${PREFIX}-denseAvatar`,
  mediumAvatar: `${PREFIX}-mediumAvatar`,
};

const StyledAvatar = styled(Avatar)(({ theme }) => ({
  [`&.${classes.defaultBackground}`]: {
    // @ts-ignore
    backgroundColor: theme.palette.background.grey,
  },

  [`&.${classes.avatarContainer}`]: {
    width: theme.spacing(4),
    height: theme.spacing(4),
    cursor: 'pointer',
  },

  [`&.${classes.avatar}`]: {
    display: 'flex',
    marginLeft: `-${theme.spacing(1)}`,
    width: theme.spacing(3),
    height: theme.spacing(3),
  },

  [`&.${classes.modalAvatar}`]: {
    display: 'block',
    width: theme.spacing(6),
    height: theme.spacing(6),
  },

  [`&.${classes.suggestedAvatar}`]: {
    width: theme.spacing(4),
    height: theme.spacing(4),
    fontSize: '1rem',
    border: 'solid 3px white',
  },

  [`&.${classes.suggestedDenseAvatar}`]: {
    width: theme.spacing(3),
    height: theme.spacing(3),
    fontSize: '0.875rem',
    border: 'solid 2px white',
  },

  [`&.${classes.denseAvatar}`]: {
    width: '18px',
    height: '18px',
    fontSize: '0.5rem',
  },

  [`&.${classes.mediumAvatar}`]: {
    fontSize: '0.75rem',
    width: '1.5rem',
    height: '1.5rem',
  },
}));

export interface AvatarObject extends Omit<AvatarProps, 'id'> {
  id?: string | number;
  firstName: string;
  lastName?: string;
  extra?: string;
  avatar?: string;
  color?: string;
  jobTitle?: JobTitle;
  handleItemClick?: Function;
  birthTermWeeks?: number;
}

interface AvatarItemType extends AvatarObject {
  group?: boolean;
  className?: string;
  size?: 'default' | 'dense' | 'medium';
  isolated?: boolean;
  active?: boolean;
  birthTermWeeks?: number;
}

const Root = styled(Box)({
  display: 'flex',
  flexDirection: 'row',
  width: '100%',
  gap: '0.25rem',
  justifyContent: 'space-between',
});

const RootGroup = styled(Box)(({ theme }) => ({
  justifyContent: 'flex-end',
  display: 'inline-flex',
  alignItems: 'center',
  marginBottom: `-${theme.spacing(1)}`,
}));

const AvatarWrapper = styled(Box)({
  textAlign: 'center',
  flex: 1,
  display: 'flex',
  flexDirection: 'row',
  gap: '0.25rem',
  alignItems: 'center',
  justifyContent: 'flex-start',
  cursor: 'pointer',
  position: 'relative',
});

const ModalAvatarItem = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'space-between',
  width: theme.spacing(10),
  margin: theme.spacing(1),
  textAlign: 'center',
}));

export const AvatarItem = ({
  firstName,
  lastName,
  avatar,
  color,
  extra,
  id,
  handleItemClick,
  group,
  className,
  size,
  active = false,
  isolated = false,
  birthTermWeeks,
}: AvatarItemType) => {
  const onItemClick = useCallback(
    () => handleItemClick(id),
    [id, handleItemClick],
  );

  if (isolated) {
    return (
      <StyledAvatar
        alt={firstName}
        src={avatar}
        className={classnames(
          !color && classes.defaultBackground,
          classes.avatarContainer,
          className,
          size === 'dense' && classes.denseAvatar,
          size === 'medium' && classes.mediumAvatar,
        )}
        sx={{
          ...(color ? { backgroundColor: color } : {}),
          ...(active
            ? {
                borderWidth: '2px',
                borderStyle: 'solid',
                borderColor: 'primary.main50',
              }
            : { borderColor: 'primary.main10' }),
        }}
      >
        {avatar || !firstName ? '' : firstName.charAt(0).toUpperCase()}
      </StyledAvatar>
    );
  }

  return group ? (
    <Tooltip
      title={`${firstName} ${lastName}`}
      aria-label={`${firstName} ${lastName}`}
      onClick={onItemClick}
    >
      <span>
        <StyledAvatar
          alt={firstName}
          src={avatar}
          className={classnames(
            !color && classes.defaultBackground,
            classes.avatarContainer,
            className,
            size === 'dense' && classes.denseAvatar,
            size === 'medium' && classes.mediumAvatar,
          )}
          sx={{
            ...(color ? { backgroundColor: color } : {}),
            ...{
              borderWidth: '2px',
              borderStyle: 'solid',
              borderColor: active ? 'primary.main50' : 'white',
            },
          }}
        >
          {avatar || !firstName ? '' : firstName.charAt(0).toUpperCase()}
        </StyledAvatar>
      </span>
    </Tooltip>
  ) : (
    <AvatarWrapper onClick={onItemClick}>
      <StyledAvatar
        alt={firstName}
        src={avatar}
        className={classnames(
          size === 'dense' && classes.denseAvatar,
          size === 'medium' && classes.mediumAvatar,
        )}
        sx={{
          backgroundColor: color,
          ...(active
            ? {
                borderWidth: '2px',
                borderStyle: 'solid',
                borderColor: 'primary.main50',
              }
            : {}),
        }}
      >
        {avatar ||
          `${(firstName || '').charAt(0)}${(lastName || '').charAt(
            0,
          )}`.toUpperCase()}
      </StyledAvatar>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <Typography
          variant="caption"
          // eslint-disable-next-line react-native/no-inline-styles
          style={{
            fontSize:
              size === 'dense'
                ? '0.75em'
                : size === 'medium'
                  ? '0.875em'
                  : '1em',
            fontWeight: 500,
            whiteSpace: 'nowrap',
            lineHeight:
              size === 'dense'
                ? '0.875rem'
                : size === 'medium'
                  ? '1rem'
                  : undefined,
          }}
        >
          {firstName}
        </Typography>
        {extra && (
          <Typography
            variant="caption"
            // eslint-disable-next-line react-native/no-inline-styles
            style={{
              fontSize: '0.625rem',
              whiteSpace: 'nowrap',
              lineHeight:
                size === 'dense'
                  ? '0.75rem'
                  : size === 'medium'
                    ? '0.75rem'
                    : undefined,
            }}
          >
            {extra +
              (birthTermWeeks && birthTermWeeks < 37
                ? ` (${birthTermWeeks} SA)`
                : '')}
          </Typography>
        )}
      </Box>
    </AvatarWrapper>
  );
};

export const AvatarListModal = ({
  children,
  open,
  onClose,
}: {
  children: React.ReactElement[];
  open: boolean;
  onClose: () => void;
}) => {
  const translate = useTranslate();
  if (!open) {
    return null;
  }

  return (
    <Dialog
      maxWidth="md"
      aria-labelledby="connected-users-dialog"
      open={open}
      onClose={onClose}
    >
      <DialogTitle onClose={onClose}>
        {translate('connectedUsers.title')}
      </DialogTitle>
      <DialogContent
        sx={{
          display: 'inline-flex',
          flexWrap: 'wrap',
        }}
      >
        {children.map((child, index) => (
          <ModalAvatarItem key={index}>
            {React.cloneElement(child, {
              className: classes.modalAvatar,
              size: 'default',
              group: false,
              isolated: true,
            })}
            <Typography variant="caption">
              {child.props.firstName} {child.props.lastName}
            </Typography>
          </ModalAvatarItem>
        ))}
      </DialogContent>
    </Dialog>
  );
};

export const AvatarList = ({
  list: rawList,
  active = [],
  max = 5,
  size = 'default',
}: {
  list: (AvatarObject | undefined)[];
  active?: number[];
  handleItemClick?: Function;
  max?: number;
  size?: 'default' | 'dense' | 'medium';
}) => {
  const [open, setOpen] = React.useState(false);
  const onClose = () => setOpen(false);
  const openModal = () => setOpen(true);

  if (!rawList) {
    return null;
  }
  const list = rawList.filter(Boolean);

  const children = list.map(({ birthTermWeeks, ...item }, index) => {
    const isActive = active.find((a) => String(a) === String(item.id));
    return (
      <AvatarItem
        key={index}
        handleItemClick={openModal}
        color={item.jobTitle ? titleToColor[item.jobTitle] : item.color}
        {...item}
        birthTermWeeks={index === 0 ? birthTermWeeks : undefined}
        size={size}
        group
        active={!!isActive}
        sx={
          isActive
            ? {
                borderWidth: '2px',
                borderStyle: 'solid',
                borderColor: 'primary.main50',
              }
            : {}
        }
      />
    );
  });

  const clampedMax = Math.max(2, Math.min(max, children.length));
  const displayedAvatars = children.slice(0, clampedMax);
  const hiddenAvatars = children.slice(clampedMax);

  const names = list
    .map(({ firstName, lastName }) => `${firstName} ${lastName}`)
    .join(' - ');

  return (
    <RootGroup>
      <AvatarListModal open={open} onClose={onClose}>
        {children}
      </AvatarListModal>
      {displayedAvatars.map((el) =>
        React.cloneElement(el, {
          className: classnames(
            classes.avatar,
            size === 'dense' && classes.denseAvatar,
          ),
        }),
      )}
      {hiddenAvatars.length > 0 ? (
        <Tooltip
          title={names}
          aria-label={names}
          sx={{ marginLeft: '-0.5rem' }}
          onClick={openModal}
        >
          <StyledAvatar
            alt={`+${hiddenAvatars.length}`}
            className={classnames(
              classes.defaultBackground,
              classes.avatarContainer,
              classes.suggestedAvatar,
              size === 'dense' && classes.suggestedDenseAvatar,
            )}
          >
            +{hiddenAvatars.length}
          </StyledAvatar>
        </Tooltip>
      ) : null}
    </RootGroup>
  );
};

export const AvatarFamilyList = ({
  appUsers,
  kids,
  handlePregnancyClick,
  handleChildClick,
  size,
}: {
  appUsers?: AvatarObject[];
  kids?: AvatarObject[];
  handlePregnancyClick: (id: number) => void;
  handleChildClick: (id: number) => void;
  size?: 'default' | 'dense' | 'medium';
}) => {
  return (
    <Root>
      {appUsers.map((item) => (
        <AvatarItem
          key={'u' + item.id}
          handleItemClick={handlePregnancyClick}
          size={size}
          {...item}
        />
      ))}
      {kids.map((item, index) => (
        <AvatarItem
          key={'c' + index}
          handleItemClick={handleChildClick}
          size={size}
          {...item}
        />
      ))}
    </Root>
  );
};
