import { useState } from 'react';
import { useNotify, useTranslate } from 'react-admin';
import { ErrorBoundary, FallbackProps } from 'react-error-boundary';
import { ScrollView, StyleSheet } from 'react-native';

import {
  Box,
  Button,
  ButtonGroup,
  FormControlLabel,
  Switch,
  SxProps,
  TextField,
  Theme,
  Typography,
} from '@mui/material';
import {
  Form,
  getScoringNumber,
  getScoringTags,
  Question,
  StepScreen,
  Template,
} from '@teammay/form-core';
import {
  FormMobileProvider,
  FormRunner,
  StepScreenRenderer,
} from '@teammay/form-mobile';

import { PrimaryButton } from './mobile-components/buttons';
import { BackButton } from './mobile-components/buttons/BackButton';
import { PressableNative } from './mobile-components/buttons/PressableNative';
import { colorTokens } from '../../../themes';

const rnStyles = StyleSheet.create({
  mobileContainer: {
    width: 300,
    height: 600,
    borderRadius: 50,
    borderColor: 'black',
    borderWidth: 10,
    borderStyle: 'solid',
    overflow: 'hidden',
    alignSelf: 'center',
  },
  mobileInnerContainer: {
    flexGrow: 1,
  },
  title: {
    fontSize: 20,
    fontWeight: 'bold',
  },
});

const styles = {
  buttonGroup: {
    color: colorTokens.content.secondary,
    borderColor: colorTokens.surface.subtle,
  },
  active: {
    color: colorTokens.content.primary,
    backgroundColor: colorTokens.surface.subtle,
  },
};

const FallBackComponent = (props: FallbackProps) => {
  const translate = useTranslate();

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: '2rem',
        alignItems: 'center',
      }}
    >
      <Typography variant="h5" color="error">
        {translate('forms.viewer.runError')}
      </Typography>
      <Typography color="error">{`${props.error}`}</Typography>
      <Button onClick={props.resetErrorBoundary} variant="contained">
        {translate('forms.viewer.retry')}
      </Button>
    </Box>
  );
};

export const MobileView = ({
  sx,
  selected,
  form,
  templates,
  questions,
}: {
  sx: SxProps<Theme>;
  selected: StepScreen | null;
  form: Form;
  templates: Template[];
  questions: Question[];
}) => {
  const [selectedMode, setSelectedMode] = useState<'viewer' | 'runner'>(
    'viewer',
  );
  const notify = useNotify();
  const translate = useTranslate();
  const [hideBack, setHideBack] = useState(false);

  return (
    <Box sx={sx}>
      <Box
        sx={{
          display: 'flex',
          width: '100%',
          flex: 1,
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <ErrorBoundary FallbackComponent={FallBackComponent}>
          <FormMobileProvider
            theme={{
              title: {
                fontSize: 17,
                fontFamily: 'Manrope',
                fontWeight: '600',
                color: colorTokens.content.primary,
                textAlign: 'center',
                marginTop: 12,
                marginBottom: 12,
              },
              text: {
                fontSize: 14,
                fontFamily: 'Manrope',
                fontWeight: '400',
                color: colorTokens.content.primary,
                textAlign: 'center',
              },
            }}
            components={{
              BackButton,
              PrimaryButton,
              Button: PressableNative,
            }}
          >
            <ScrollView
              style={rnStyles.mobileContainer}
              contentContainerStyle={rnStyles.mobileInnerContainer}
            >
              {selectedMode === 'viewer' && (
                <StepScreenRenderer
                  stepScreen={selected}
                  submit={(answer) => {
                    notify(
                      `${translate('forms.runner.submitClicked')}: ${answer}`,
                      { type: 'success' },
                    );
                  }}
                />
              )}
              {selectedMode === 'runner' && (
                <FormRunner
                  form={form}
                  templates={templates}
                  questions={questions}
                  totalQuestions={form.questionCount ?? 0}
                  onFormFinished={(answers) => {
                    const score = getScoringNumber(answers, questions) ?? ' - ';
                    const tags = getScoringTags(answers, questions) ?? ' - ';
                    notify('forms.runner.completedForm', {
                      type: 'success',
                      messageArgs: { score, tags },
                    });
                  }}
                  onCancelForm={() => {
                    notify('forms.runner.canceledForm', {
                      type: 'warning',
                    });
                  }}
                  hideBack={hideBack}
                />
              )}
            </ScrollView>
          </FormMobileProvider>
        </ErrorBoundary>
      </Box>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          py: 1,
          px: 2,
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            gap: 1,
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <TextField
            value={form?.questionCount ?? 0}
            type="number"
            label={translate('forms.runner.questionCount')}
            variant="standard"
            disabled={true}
            sx={{ minWidth: 0 }}
          />
          <FormControlLabel
            control={<Switch onClick={() => setHideBack((prev) => !prev)} />}
            label={translate('forms.runner.canBack')}
            checked={!hideBack}
          />
        </Box>

        <ButtonGroup variant="outlined" aria-label="Basic button group">
          <Button
            style={{
              ...styles.buttonGroup,
              ...(selectedMode === 'viewer' ? styles.active : {}),
            }}
            onClick={() => setSelectedMode('viewer')}
          >
            Viewer
          </Button>
          <Button
            style={{
              ...styles.buttonGroup,
              ...(selectedMode === 'runner' ? styles.active : {}),
            }}
            onClick={() => setSelectedMode('runner')}
          >
            Runner
          </Button>
        </ButtonGroup>
      </Box>
    </Box>
  );
};
