import { useTranslate } from 'react-admin';
import { Link } from 'react-router-dom';

import { Loader } from '@components/Loader';
import { Box, Card, CardContent, Typography } from '@mui/material';

import { getTimeAgo } from '../../utils/date';
import { useChangelogs } from '../common/changelog.hook';

export const ChangelogCard = () => {
  const translate = useTranslate();
  const { changelogs, isLoading } = useChangelogs();

  return (
    <Card elevation={2}>
      <CardContent>
        <Typography
          variant="h6"
          color="primary"
          sx={{ fontWeight: 'bold', marginBottom: '0.5rem' }}
        >
          {translate('changelog.title')}
        </Typography>
        {isLoading ? (
          <Loader />
        ) : changelogs && changelogs.length > 0 ? (
          changelogs.map((changelog) => (
            <Link
              key={changelog.id}
              to={`/changelogs/${changelog.id}`}
              style={{ textDecoration: 'none' }}
            >
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                }}
              >
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                  }}
                >
                  <Typography
                    sx={{
                      marginRight: '1rem',
                      fontWeight: changelog.read ? 'normal' : 'bold',
                      '&::before': {
                        content: '""',
                        display: 'inline-block',
                        width: '0.5rem',
                        height: '0.5rem',
                        borderRadius: '50%',
                        backgroundColor: changelog.read
                          ? 'background.grey'
                          : 'error.main',
                        marginRight: '0.5rem',
                      },
                    }}
                  >
                    {changelog.title}
                  </Typography>
                  <Typography color="gray" variant="caption">
                    {getTimeAgo(changelog.publishedAt)}
                  </Typography>
                </Box>
                <Typography color="gray">{changelog.author}</Typography>
              </Box>
            </Link>
          ))
        ) : (
          <Typography variant="body1" color="textSecondary">
            {translate('changelog.empty')}
          </Typography>
        )}
      </CardContent>
    </Card>
  );
};
