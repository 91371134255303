import { i18nProvider } from '../App';
// import { JobTitle, JobTitleToString } from '../types/user';
import { JobTitleToString } from '../types/user';

export const sexChoices = () => [
  { id: 'm', name: i18nProvider.translate('common.male') },
  { id: 'f', name: i18nProvider.translate('common.female') },
];

export const subscriptionChoices = () => [
  { id: 'active', name: i18nProvider.translate('subscription.paid') },
  { id: 'trialing', name: i18nProvider.translate('subscription.trial') },
  { id: 'bonus', name: i18nProvider.translate('subscription.bonus') },
  { id: 'affiliated', name: i18nProvider.translate('subscription.affiliated') },
];

export const localeChoices = () => [
  { id: 'fr', name: i18nProvider.translate('common.french') },
  { id: 'en', name: i18nProvider.translate('common.english') },
  { id: 'es', name: i18nProvider.translate('common.spanish') },
];

export const titleChoices = () =>
  Object.entries(JobTitleToString).map(([job, value]) => ({
    id: job,
    name: i18nProvider.translate(value),
  }));
