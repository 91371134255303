import { useState } from 'react';
import { useNotify, useTranslate } from 'react-admin';
import { useForm } from 'react-hook-form';

import { TextInput } from '@components/forms/textInput';
import { DialogTitle } from '@components/generic/Dialog';
import { useMutation } from '@hooks/queryWrappers';
import ThumbDownIcon from '@mui/icons-material/ThumbDown';
import ThumbUpIcon from '@mui/icons-material/ThumbUp';
import {
  Box,
  Dialog,
  DialogActions,
  Button,
  DialogContent,
  IconButton,
} from '@mui/material';

import { logContentSuggestionFeedback } from '../../../../analytics/events';
import { colorTokens } from '../../../../themes';

const FeedbackButton = ({
  isPositive = false,
  onClick,
}: {
  isPositive?: boolean;
  onClick: () => void;
}) => {
  return (
    <IconButton
      aria-label={isPositive ? 'positive feedback' : 'negative feedback'}
      sx={{
        color: isPositive
          ? colorTokens.content.subtle
          : colorTokens.content.danger,
      }}
      onClick={onClick}
      size="small"
    >
      {isPositive ? <ThumbUpIcon /> : <ThumbDownIcon />}
    </IconButton>
  );
};

interface FormValues {
  feedback?: string;
}

interface FeedbackValues {
  isPositive: boolean;
  feedback: string;
  subjectId: number;
}

const FeedbackModal = ({
  open,
  onClose,
  subjectId,
}: {
  open: boolean;
  onClose: () => void;
  subjectId: number;
}) => {
  const translate = useTranslate();

  const notify = useNotify();
  const { handleSubmit, control } = useForm<FormValues>({
    defaultValues: { feedback: '' },
  });

  const sendFeedback = useContentSuggestionFeedback();

  const onSubmit = handleSubmit(async (values: FormValues) => {
    await sendFeedback({
      isPositive: false,
      feedback: values.feedback,
      subjectId,
    });
    logContentSuggestionFeedback(subjectId, false);
    onClose();
    notify('common.negativeFeedbackSubmitted', { type: 'success' });
  });

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle onClose={onClose}>
        <ThumbDownIcon color="error" />
        Feedback
      </DialogTitle>
      <DialogContent>
        <TextInput
          autoFocus
          control={control}
          name="feedback"
          label={translate('common.feedback')}
          helperText={translate('common.feedbackHelpText')}
          multiline
          rows={4}
          fullWidth
        />
      </DialogContent>
      <DialogActions>
        <Button color="primary" onClick={onSubmit}>
          {translate('common.save')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const useContentSuggestionFeedback = () => {
  const { mutateAsync: sendFeedback } = useMutation<any, any, FeedbackValues>(
    ['send_content_suggestion_feedback'],
    (data: FeedbackValues) => ({
      method: 'POST',
      url: '/api/content-suggestion/feedback',
      data,
    }),
  );

  return sendFeedback;
};

export const ContentSuggestionFeedback = ({
  subjectId,
}: {
  subjectId: number;
}) => {
  const notify = useNotify();
  const sendFeedback = useContentSuggestionFeedback();
  const [open, setOpen] = useState(false);
  const onClose = () => setOpen(false);
  const onPositiveClick = async () => {
    logContentSuggestionFeedback(subjectId, true);
    await sendFeedback({ isPositive: true, subjectId, feedback: '' });
    notify('common.positiveFeedbackSubmitted', {
      type: 'success',
    });
  };
  const onNegativeClick = () => {
    setOpen(true);
  };

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-start',
        alignItems: 'center',
        gap: 1,
      }}
    >
      <FeedbackModal open={open} onClose={onClose} subjectId={subjectId} />
      <FeedbackButton onClick={onPositiveClick} isPositive />
      <FeedbackButton onClick={onNegativeClick} />
    </Box>
  );
};
